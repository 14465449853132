import {isEmpty, isNil, isObject} from 'lodash';
import {$Keys} from 'utility-types';

import {getSortProperty, isSortDescending} from './string';

export const convertToElasticSortObject = (sort: string) => {
  const sortProp = getSortProperty(sort);
  const descendingSort = isSortDescending(sort);

  return {[sortProp]: descendingSort ? 'desc' : 'asc'};
};

export const getElasticSearchTerms = <T extends object>(query: T, fields: $Keys<T>[]) => {
  return fields.filter((key) => query[key]).map((key) => ({term: {[key]: query[key]}}));
};

export const getSort = (sort: string) => {
  if (sort) {
    return [convertToElasticSortObject(sort)];
  }

  return [];
};

const getElasticQueryKey = ({ elasticKeyDictionary = {}, key = '' }) => (
  elasticKeyDictionary[key] || key
);

const getObjDotKey = ({ key = '', parentKey = '' }) => (
  parentKey ? `${parentKey}.${key}` : key
);

const parseKeyValueQueryString = (key = '', value, isArray = false) => {
  const parsedValue = (typeof value === 'string' && value !== '*')
    ? `"${value}"`
    : value;

  return isArray ? `${key}:(${parsedValue})` : `${key}:${parsedValue}`
};

const convertKeyValueToElasticQueryString = ({ 
  elasticKeyDictionary = {}, 
  parentKey = '', 
  key = '', 
  value
}) => {
  const dotKey = getObjDotKey({ key, parentKey });
  const elasticKey = getElasticQueryKey({ elasticKeyDictionary, key: dotKey });

  if(Array.isArray(value)) {
    // Convert to 'term:("value" OR "value")' string
    const arrayValue = value.join('" OR "');
    return parseKeyValueQueryString(elasticKey, arrayValue, true);
  }

  if(isObject(value)) {
    // Recursively call the function passing the value and return the query_string
    return convertObjToElasticQueryString({ elasticKeyDictionary, parentKey: dotKey, obj: value });
  }

  // Convert to 'term:"value"' unless it's a wildcard, otherwise it'll break the query
  return parseKeyValueQueryString(elasticKey, value);
};

const getNonNullObjKeys = obj => Object.keys(obj).filter(key => (
  !isNil(obj[key])
  && (
    (isObject(obj[key]) && !isEmpty(obj[key]))
    || '' !== obj[key]
  )
));

export const convertObjToElasticQueryString = ({ 
  elasticKeyDictionary = {}, 
  parentKey = '', 
  obj = {},
  operator = 'AND',
  rawQueryString = ''
}): string => {
  /** CONVERSIONS TO BUILD A QUERY STRING
   * Array => term:(\"value" OR \"value\")
   * Field => term:\"value\"
   * Query String => "term:\"value\" AND term:(\"value\" OR \"value\") ..."
   * */
  const objKeys = getNonNullObjKeys(obj);

  const queryString =  objKeys
    .map(key => convertKeyValueToElasticQueryString({
      elasticKeyDictionary,
      parentKey, 
      key, 
      value: obj[key]
    }))
    .join(` ${operator} `);

  return isNil(rawQueryString) || !`${rawQueryString}`.trim()
    ? queryString
    : `${queryString} ${operator} ${rawQueryString}`;
};
