import {push} from 'react-router-redux';
import {MarketsSortOption} from '__generated__/globalTypes';
import {Action} from 'redux-act';
import {call, put, takeLatest} from 'redux-saga/effects';

import {MediaCategorySubFilter} from 'models';
import {MediaTypeRestService, PanelRestService} from 'services';
import {searchMarkets} from 'services/MarketService';

import {SagaReturnType} from '../../../utils/sagas';
import {actions} from './actions';

const fetchMarketsData = (includeInactiveMarkets: boolean) => {
  return searchMarkets({
    limit: 200,
    sort: MarketsSortOption.NAME_ASC,
    query: `${!includeInactiveMarkets ? 'active:true' : '*'}`,
  });
};

const fetchMediaTypeCategories = () => {
  return PanelRestService.getInstance().getMediaTypeCategories();
};

const fetchSynonymsOptions = (mediaCategory: string, mediaSubCategory: string) => {
  return MediaTypeRestService.getInstance().getSynonymsOptions({ mediaCategory, mediaSubCategory });
};

function* fetchMarketsSaga(action: Action<boolean>) {
  const includeInactiveMarkets = action.payload;

  yield put(actions.setIncludeInactiveMarkets(includeInactiveMarkets));

  try {
    const {searchMarkets}: SagaReturnType<typeof fetchMarketsData> = yield call(
      fetchMarketsData,
      includeInactiveMarkets,
    );
    yield put(actions.setMarkets(searchMarkets.data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllMarketsSaga() {
  try {
    const {searchMarkets}: SagaReturnType<typeof fetchMarketsData> = yield call(
      fetchMarketsData,
      true,
    );
    yield put(actions.setAllMarkets(searchMarkets.data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchMediaTypeCategoriesSaga() {
  try {
    const mediaTypeCategories = yield call(fetchMediaTypeCategories);
    yield put(actions.setMediaTypeCategories(mediaTypeCategories));
  } catch (e) {
    console.error(e);
  }
}

function* fetchSynonymsOptionsSaga(action: Action<MediaCategorySubFilter>) {
  try {
    const {category, subCategory} = action.payload;
    const mediaSynonyms = yield call(fetchSynonymsOptions, category, subCategory);
    yield put(actions.setMediaSynonyms(mediaSynonyms));
    return mediaSynonyms;
  } catch (e) {
    console.error(e);
  }
}

function* navigateToRoute(action: Action<string>) {
  const path = action.payload;
  yield put(push(path));
}

export function* appSaga() {
  yield takeLatest(actions.navigateToRoute, navigateToRoute);
  yield takeLatest(actions.fetchMarkets, fetchMarketsSaga);
  yield takeLatest(actions.fetchAllMarkets, fetchAllMarketsSaga);
  yield takeLatest(actions.fetchMediaTypeCategories, fetchMediaTypeCategoriesSaga);
  yield takeLatest(actions.fetchSynonymsOptionsWithParams, fetchSynonymsOptionsSaga);
}
