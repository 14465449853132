import {createSelector} from 'reselect';

import {actions} from 'config/redux/global/actions';
import {arrayToIndexed, flatMap} from 'utils/array';
import {toOptions} from 'utils/mediaTypes';

import type {RootState} from 'config/redux/rootReducer';
const getMediaTypeCategories = (state: RootState) => state.app.mediaTypeCategories;
const getSynonymsOptions = (state: RootState) => state.app.mediaSynonyms;
const getCategoryOptions = createSelector([getMediaTypeCategories], (mediaTypeCategories) =>
  toOptions(mediaTypeCategories),
);

const getIndexed = createSelector([getMediaTypeCategories], (mediaTypeCategories) => {
  const subcategories = flatMap(mediaTypeCategories, (c) => c.subcategories);
  return {
    indexedSubcategories: arrayToIndexed(subcategories, 'name'),
    indexedCategories: arrayToIndexed(mediaTypeCategories, 'name'),
  };
});

export const mapStateToProps = (state: RootState) => ({
  categoryOptions: getCategoryOptions(state),
  mediaTypeCategories: getMediaTypeCategories(state),
  ...getIndexed(state),
  mediaSynonyms: getSynonymsOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchMediaTypeCategories: () => dispatch(actions.fetchMediaTypeCategories()),
  fetchSynonymsOptions: (category?: string, subCategory?: string) =>
    dispatch(actions.fetchSynonymsOptionsWithParams({category, subCategory})),
});

export type MapStateToProps = ReturnType<typeof mapStateToProps>;
export type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
