import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Autocomplete,
  AutocompleteSize,
  FilterActionPopper,
  MultiSelectList,
  PopperContentSection,
} from '@onsmart/ui-kit';
import equal from 'fast-deep-equal';
import * as _get from 'lodash.get';
import * as _isEmpty from 'lodash.isempty';

import {map, sortBy} from 'utils/array';
import {compose} from 'utils/functional';
import {toOptions} from 'utils/mediaTypes';

import {mapDispatchToProps, mapStateToProps} from './selectors';
import {styles} from './styles';

import type {AutocompleteProps, onCloseType, Option} from '@onsmart/ui-kit';
import type {MediaFilter, MediaType} from 'models/MediaType';
import type {MapDispatchToProps, MapStateToProps} from './selectors';
import type {Styles} from './styles';

interface ExternalProps {
  value: MediaFilter;
  onPopperClose: (temp?: Partial<MediaFilter>) => void;
  onChange: (media: Partial<MediaFilter>, ev?: React.SyntheticEvent) => void;
  open: boolean;
  hasChanges: boolean;
  resetTempValue: () => void;
  anchorEl: HTMLElement;
}

interface Props extends ExternalProps, MapStateToProps, MapDispatchToProps, Styles {}

interface State {
  isMenuOpen: boolean;
  selected: Partial<MediaFilter>;
}

class MediaTypeFilterPopper extends Component<Props, State> {
  filterComponent: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      selected: props.value,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchSynonymsOptions(this.state.selected);
  }

  fetchSynonymsOptions = (selected: Partial<MediaFilter>) => {
    const {category, subCategory} = selected || ({} as MediaFilter);
    this.props.fetchSynonymsOptions(category, subCategory);
  };

  UNSAFE_componentWillUpdate(nextProps: Props, nextState: State) {
    const {category, subCategory} = this.state.selected || ({} as MediaFilter);
    if (
      _get(nextState, 'selected.category') !== category ||
      _get(nextState, 'selected.subCategory') !== subCategory
    ) {
      this.fetchSynonymsOptions(nextState.selected);
    }

    if (!equal(this.props.value, nextProps.value) && !equal(nextProps.value, this.state.selected)) {
      this.setState({selected: nextProps.value});
    }
  }

  private setSelectedValue = (param: Partial<{[P in keyof MediaFilter]: MediaFilter[P]}>) => {
    this.setState((state) => ({
      ...state,
      selected: param,
    }));
  };

  handleCategoryChange = (op: AutocompleteProps['options'][number]) => {
    const {category} = this.state.selected;
    if (op && category === op.value) return;

    const selected = op ? {category: op.value} : {};
    this.setSelectedValue(selected);
  };

  handleSubcategoryChange = (op: AutocompleteProps['options'][number]) => {
    const {category, subCategory: old} = this.state.selected;
    if (op && old === op.value) return;

    this.setSelectedValue(
      Object.assign({}, category && {category}, op && op.value && {subCategory: op.value}),
    );
  };

  handleSubmit = (ev: any) => {
    const {onChange, value} = this.props;
    const {selected} = this.state;

    if (!equal(value, selected)) {
      onChange(selected, ev);
    }
  };

  componentDidMount() {
    this.props.fetchMediaTypeCategories();
  }

  handleSelectedValueChange = (value: Option[]) => {
    const {category, subCategory} = this.state.selected;
    const validValues = value.filter((m) => !_isEmpty(m));
    const names = validValues.map((m) => m.label);

    this.setSelectedValue(
      Object.assign(
        {},
        category && {category},
        subCategory && {subCategory},
        names.length && {names},
      ),
    );
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {selected} = this.state;
    if (
      this.checkStateNameExits(prevState) &&
      selected.names !== prevState.selected.names &&
      selected.names.length > prevState.selected.names.length
    ) {
      if (this.filterComponent && this.filterComponent.scrollbars) {
        this.filterComponent.scrollbars.scrollToBottom(true);
      }
    }
  }

  checkStateNameExits = (prevState: State) => {
    const {selected} = this.state;
    return selected && prevState.selected && selected.names && prevState.selected.names;
  };

  render() {
    const {anchorEl, open, hasChanges, resetTempValue} = this.props;
    const {selected} = this.state;
    const {category, subCategory, names} = selected || ({} as MediaFilter);

    const isSelectedEmpty = _isEmpty(category) && _isEmpty(subCategory) && _isEmpty(names);

    return (
      <FilterActionPopper
        innerRef={(ref) => (this.filterComponent = ref)}
        anchorEl={anchorEl}
        open={open}
        onClose={this.handleClose}
        headerTitle="Media Type"
        hasChanges={hasChanges}
        onCancelLabel="Reset"
        onCancel={hasChanges && resetTempValue}
        disabled={isSelectedEmpty}
        onSubmit={this.handleSubmit}
      >
        {this.renderContent()}
      </FilterActionPopper>
    );
  }

  handleMenuOpen = () => {
    this.setState({isMenuOpen: true});
  };

  handleMenuClose = () => {
    this.setState({isMenuOpen: false});
  };

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>,
    type?: onCloseType,
  ) => {
    if (type === 'away' && this.state.isMenuOpen) {
      return;
    }

    const {selected} = this.state;

    if (!equal(selected, this.props.value)) {
      this.props.onPopperClose(selected);
    } else {
      this.props.onPopperClose();
    }
  };

  renderContent = () => {
    const {classes, categoryOptions} = this.props;
    const subcategories = this.getSubcategoryOptions();
    const {selected} = this.state;
    const options = this.getOptions();

    const {category, subCategory} = selected || ({} as MediaFilter);

    return (
      <>
        <PopperContentSection>
          <Autocomplete
            autoFocus
            onMenuOpen={this.handleMenuOpen}
            onMenuClose={this.handleMenuClose}
            label="Category"
            size={AutocompleteSize.small}
            options={categoryOptions}
            onChange={this.handleCategoryChange}
            value={category ? {value: category, label: category} : null}
            placeholder=""
            isClearable
          />
        </PopperContentSection>
        <PopperContentSection>
          <Autocomplete
            onMenuOpen={this.handleMenuOpen}
            onMenuClose={this.handleMenuClose}
            label="Subcategory"
            size={AutocompleteSize.small}
            options={subcategories}
            onChange={this.handleSubcategoryChange}
            value={subCategory ? {value: subCategory, label: subCategory} : null}
            placeholder=""
            isClearable
          />
        </PopperContentSection>
        <MultiSelectList
          classes={{selectWithValues: classes.selectedWithValues}}
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          label="Media Type"
          options={options}
          onChange={this.handleSelectedValueChange}
          values={this.getFilterValues()}
        />
      </>
    );
  };

  getSubcategoryOptions = () => {
    const {selected} = this.state;
    const {category} = selected || ({} as MediaFilter);
    const {indexedCategories} = this.props;
    return indexedCategories && category && indexedCategories[category]
      ? toOptions(indexedCategories[category].subcategories)
      : [];
  };

  getOptions = () => {
    const {selected} = this.state;
    const {mediaSynonyms} = this.props;
    const {names = []} = selected || ({} as MediaFilter);
    let mediaTypes = [];

    mediaTypes = mediaSynonyms || [];

    const mediaTypeToOption = (mediaType: MediaType) => ({
      label: mediaType,
      value: mediaType,
    });

    const filterByValue = (mediaTypes: string[] = []) =>
      mediaTypes.filter((m) => !(names || []).includes(m));

    return compose(map(mediaTypeToOption), sortBy('name'), filterByValue)(mediaTypes);
  };

  getFilterValues = () => {
    const {mediaSynonyms} = this.props;
    const {selected = {}} = this.state;

    if (!mediaSynonyms) {
      return null;
    }

    const newValue = (selected.names || [])
      .map((mediaType) => mediaSynonyms.find((m) => m === mediaType))
      .filter((m) => !!m)
      .map((m) => ({value: m, label: m}));

    return newValue;
  };
}

export default withStyles(styles)(
  connect<MapStateToProps, MapDispatchToProps, ExternalProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(MediaTypeFilterPopper),
);
