import React from 'react';
import {BaseFilter, BaseFilterProps, MultipleCheckFilterPopper} from '@onsmart/ui-kit';
import isEqual from 'lodash-es/isEqual';

import {CurationStatusEnum} from 'models';

import Chip from './Chip';

type Props = BaseFilterProps<CurationStatusEnum[]> & {
  options: CurationStatusEnum[];
};

const FILTER_ID = 'curationStatus' as const;

class StatusFilter extends BaseFilter<Props, string[]> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Curation Status',
    description: 'Filter by curation status.',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: StatusFilter.defaultFilterAddOption,
    options: [
      CurationStatusEnum.draft,
      CurationStatusEnum.review,
      CurationStatusEnum.notFound,
      CurationStatusEnum.completed,
      CurationStatusEnum.published,
      CurationStatusEnum.hold,
    ],
  } as Partial<Props>;

  render() {
    const {value = [], initialValue = [], context, isOpen: open, tempValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;

    return (
      <div>
        {!!value.length && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {open && (
          <MultipleCheckFilterPopper
            headerTitle={this.props.filterAddOption.title}
            options={this.props.options}
            open={open}
            anchorEl={anchorEl}
            value={tempValue || value}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  }
}

export default StatusFilter;
