import {Query} from 'bkn-ui';
import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import {Definitions, Indexed} from 'models';

import {actions} from './actions';

export type Category = {
  category: string;
  total: number;
};

export type DefinitionsChange = Definitions & {
  hasUnsavedChanges: boolean;
};

export type KeywordsChange = {
  keyword: string;
  hasUnsavedChanges: boolean;
};

export const initialState = {
  keywords: [] as string[],
  category: [] as Category[],
  definitions: {} as Definitions,
  unSavedChanges: {} as Indexed<DefinitionsChange>,
  selectedCategory: '' as string,
  selectedKeyword: '' as string,
  query: {
    limit: 100,
    skip: 0,
    sort: 'name',
  } as Query,
  searchTerm: '',
  total: 0,
  alreadyUsed: false,
  loadingCategories: false,
  loadingKeywords: false,
  loadingDefinitions: false,
  firstLoad: true,
};

const keywords = createReducer({}, initialState.keywords).on(
  actions.setKeywords,
  (_state, payload) => [...payload],
);

const definitions = createReducer({}, initialState.definitions)
  .on(actions.setDefinitions, (_state, payload) => payload)
  .on(actions.setSelectedCategory, () => initialState.definitions)
  .on(actions.setAlreadyUsed, () => initialState.definitions);

const category = createReducer({}, initialState.category).on(
  actions.setCategory,
  (_state, payload) => [...payload],
);

const query = createReducer({}, initialState.query).on(
  actions.setQuery,
  (_state, payload) => payload,
);

const searchTerm = createReducer({}, initialState.searchTerm).on(
  actions.setSearchTerm,
  (_state, term) => term,
);

const total = createReducer({}, initialState.total).on(
  actions.setTotal,
  (state, payload) => payload,
);

const alreadyUsed = createReducer({}, initialState.alreadyUsed).on(
  actions.setAlreadyUsed,
  (state, payload) => payload,
);

const selectedCategory = createReducer({}, initialState.selectedCategory).on(
  actions.setSelectedCategory,
  (state, payload) => payload,
);
const selectedKeyword = createReducer({}, initialState.selectedKeyword).on(
  actions.setSelectedKeyword,
  (state, payload) => payload,
);

const loadingCategories = createReducer({}, initialState.loadingCategories)
  .on(actions.fetchCategory, () => true)
  .on(actions.setCategory, () => false);

const loadingKeywords = createReducer({}, initialState.loadingKeywords)
  .on(actions.fetchKeywordsList, () => true)
  .on(actions.setKeywords, () => false)
  .on(actions.setAlreadyUsed, () => true);

const loadingDefinitions = createReducer({}, initialState.loadingDefinitions)
  .on(actions.fetchKeywordDefinition, () => true)
  .on(actions.setDefinitions, () => false);

const firstLoad = createReducer({}, initialState.firstLoad)
  .on(actions.fetchKeywordsList, () => false)
  .on(actions.setAlreadyUsed, () => false);

const unSavedChanges = createReducer(
  {},
  initialState.unSavedChanges,
).on(actions.setSelectedUnsavedChanges, (state, payload) => ({...state, ...payload}));

type State = typeof initialState;

export default combineReducers<State>({
  keywords,
  category,
  query,
  searchTerm,
  total,
  definitions,
  loadingKeywords,
  alreadyUsed,
  selectedCategory,
  selectedKeyword,
  loadingCategories,
  loadingDefinitions,
  firstLoad,
  unSavedChanges,
});
