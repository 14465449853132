import React from 'react';
import {BaseFilter, BaseFilterProps, MultipleCheckFilterPopper} from '@onsmart/ui-kit';
import isEqual from 'lodash-es/isEqual';

import {Status} from 'models/Panel';

import Chip from './Chip';

type Props = BaseFilterProps<Status[]> & {
  chipPrefix: string;
  options: Status[];
};

const FILTER_ID = 'panelStatus' as const;

class StatusFilter extends BaseFilter<Props, string[]> {
  static id = FILTER_ID;
  static defaultFilterAddOption = (modelName: string) => ({
    id: FILTER_ID,
    title: `${modelName} Status`,
    description: `Filter by ${modelName.toLowerCase()} status.`,
  });

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: StatusFilter.defaultFilterAddOption('Entries'),
    options: [Status.active, Status.inactive, Status.unavailable],
  } as Partial<Props>;

  render() {
    const {value = [], initialValue = [], context, isOpen: open, tempValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;

    return (
      <div>
        {!!value.length && (
          <Chip
            chipPrefix={this.props.chipPrefix}
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {open && (
          <MultipleCheckFilterPopper
            headerTitle={this.props.filterAddOption.title}
            options={this.props.options}
            open={open}
            anchorEl={anchorEl}
            value={tempValue || value}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  }
}

export default StatusFilter;
