const commonvars = {
  app: {
    name: process.env.APP_NAME as string,
    version: process.env.npm_package_version as string,
    commitHash: (process.env.COMMITHASH || '').slice(0, 8),
  },
  apiUrl: {
    queryApi: process.env.ONSMART_QUERY_API_V1_URL,
    keyword: process.env.GSP_MAD_API_V3_KEYWORD_URL as string,
    panel: process.env.GSP_MAD_API_V3_PANEL_URL as string,
    activity: process.env.GSP_ACTIVITY_API_V1_EVENT_URL as string,
    images: process.env.SERVICE_OFM_REF_IMG_URL as string,
    imsMedia: process.env.SERVICE_IMS_MEDIA_URL as string,
    imsMediaParams: process.env.MEDIA_TYPE_WHITELIST as string,
    road: process.env.ONSMART_CURATION_ROAD_API_V1_URL as string,
    coreApi: process.env.ONSMART_CORE_API_V1_URL as string,
  },
  apiKey: {
    keywordKey: process.env.GSP_MAD_API_V3_KEYWORD_API_KEY as string,
  },
  auth: {
    account: process.env.ONSMART_ACCOUNT_UI_URL,
    gateway: process.env.ONSMART_AUTH_GATEWAY_V1_URL,
  },
  mapbox: {
    token: process.env.MAPBOX_API_KEY as string,
    styleUrl: process.env.MAPBOX_STYLE_URL as string,
    defaultLayer: process.env.MAPBOX_DEFAULT_LAYER as string,
  },
  googleMaps: {
    token: 'AIzaSyDKiaG9eStzG8dJ8uxWr8xkl5ujrhPDU0o',
    url: 'https://maps.googleapis.com/maps/api',
  },
  imageProxy: (process.env.IMG_PROXY as string) || '',
  sentry: {
    dsn: 'https://ba947c3d8a49477892b3a9285e46fbbf@o489580.ingest.sentry.io/191843',
    release: process.env.COMMITHASH as string,
  },
};

export const environment = {
  currentType: process.env.NODE_ENV || ('development' as string),
  settings: commonvars,
};
