import type {Dispatch} from 'react-redux';
import {bindActionCreators as bindActions} from 'redux';

import {actions} from 'config/redux/global/actions';
import {RootState} from 'config/redux/rootReducer';

const getMarkets = (state: RootState) => state.app.allMarkets;
const getOptions = (state: RootState) =>
  state.app.markets
    .map((m) => ({text: `${m.name} - ${m.code}`, value: m.code}))
    .sort((a, b) => a.text.localeCompare(b.text));

export const mapStateToProps = (state: RootState) => ({
  includeInactiveMarkets: state.app.includeInactiveMarkets,
  options: getOptions(state),
  markets: getMarkets(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  ...bindActions(
    {
      fetchAllMarkets: actions.fetchAllMarkets,
    },
    dispatch,
  ),
});

export type MapStateToProps = ReturnType<typeof mapStateToProps>;
export type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
