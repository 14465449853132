import axios, {AxiosInstance} from 'axios';

import {environment} from 'config';
import {Definitions} from 'models';

export class KeywordRestService {
  private static instance: KeywordRestService;
  private axiosInstance: AxiosInstance;

  private constructor() {
    const baseURL = environment.settings.apiUrl.keyword;

    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'x-api-key': environment.settings.apiKey.keywordKey,
      },
    });
  }

  static getInstance() {
    return new KeywordRestService();
  }

  public getAllCategories(): Promise<any> {
    return this.axiosInstance
      .get('/category')
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        throw e;
      });
  }

  public getKeywords(category: string, alreadyUsed: boolean = false): Promise<any> {
    const urlCategory = encodeURIComponent(category);
    return this.axiosInstance
      .get<string>(`/category/${urlCategory}?already_used=${alreadyUsed}`)
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        throw e;
      });
  }

  public getDefinitions(keyword: string): Promise<any> {
    return this.axiosInstance
      .get(`/def/${keyword}`)
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        throw e;
      });
  }

  public saveKeyword(keyword: Definitions, keywordId?: string): Promise<Definitions> {
    if (!keyword) throw new Error('Parameter keyword is required');
    return this.axiosInstance
      .post<Definitions>(`/def/${keywordId}`, keyword)
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        throw e;
      });
  }

  public delete(keyword: Definitions): Promise<Definitions> {
    if (!keyword) throw new Error('Parameter keyword is required');
    return this.axiosInstance
      .post<Definitions>('/def', keyword)
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        throw e;
      });
  }

  public remove(id: string | number) {
    if (!id) throw new Error('Parameter id is required');

    return this.axiosInstance.delete(`/def/${id}`).then((r) => {
      return r.data;
    });
  }
}
