import {CurationStatus, PanelStatus} from '__generated__/globalTypes';

import {aggsMediaNames} from 'utils/models/markets';
import {UnPromisify} from 'utils/promise';

import {GetAllMarketsWithPanelCount} from './__generated__/GetAllMarketsWithPanelCount';
import {
  GetMarketPanelsSummary,
  GetMarketPanelsSummaryVariables,
} from './__generated__/GetMarketPanelsSummary';
import {SearchMarkets, SearchMarketsVariables} from './__generated__/SearchMarkets';
import {CoreClient, gql} from './graphql/CoreClient';

const SEARCH_MARKETS = gql`
  query SearchMarkets($sort: MarketsSortOption = NAME_ASC, $limit: Int, $query: String) {
    searchMarkets(sort: [$sort], limit: $limit, query: $query) {
      total
      data {
        id
        name
        code
        active
        transitAgency
      }
    }
  }
`;

const GET_ALL_MARKETS_WITH_PANEL_COUNT = gql`
  query GetAllMarketsWithPanelCount {
    searchMarkets(limit: 200) {
      total
      data {
        id
        name
        code
        active
        panelCount {
          active
        }
      }
    }
  }
`;

const GET_MARKET_PANELS_SUMMARY = gql`
  query GetMarketPanelsSummary($panelStatus: [PanelStatus!], $mediaNames: [String!]) {
    getMarketPanelsSummary(panelStatus: $panelStatus, mediaNames: $mediaNames) {
      data {
        marketCode
        withPhotos
        withoutPhotos
        panelStatus {
          type
          curationStatus {
            type
            total
          }
        }
      }
    }
  }
`;

function getAllMarketsWithPanelCount() {
  const options = {
    cache: {
      key: 'getAllMarketsWithPanelAccount',
      staleTime: Infinity
    }
  };

  return CoreClient.getInstance().request<GetAllMarketsWithPanelCount>(
    GET_ALL_MARKETS_WITH_PANEL_COUNT,
    null, 
    options
  );
};

export function searchMarkets(params: SearchMarketsVariables) {
  const options = {
    cache: {
      key: 'searchMarkets',
      staleTime: Infinity
    }
  };

  return CoreClient.getInstance().request<SearchMarkets, SearchMarketsVariables>(
    SEARCH_MARKETS, 
    params,
    options
  );
};

function getMarketPanelsSummary(params: GetMarketPanelsSummaryVariables) {
  return CoreClient.getInstance().request<GetMarketPanelsSummary, GetMarketPanelsSummaryVariables>(
    GET_MARKET_PANELS_SUMMARY,
    params
  );
};

export async function aggregation() {
  const [markets, aggregation] = await Promise.all([
    getAllMarketsWithPanelCount().then((r) => r.searchMarkets),
    getMarketPanelsSummary({panelStatus: [PanelStatus.ACTIVE], mediaNames: aggsMediaNames}).then(
      (r) => r.getMarketPanelsSummary,
    ),
  ]);

  const indexedSummary = aggregation.data.reduce((acc, m) => {
    const {panelStatus, withPhotos, withoutPhotos} = m;
    const activePanelStatus = getActivePanelStatus(panelStatus);
    const curationStatusTotal = getCurationStatusTotal(activePanelStatus.curationStatus);
    acc[m.marketCode] = {...curationStatusTotal, withPhotos, withoutPhotos};
    return acc;
  }, {} as Record<string, Record<CurationStatus, number> & {withPhotos: number; withoutPhotos: number}>);

  return {
    total: markets.total,
    data: markets.data.map((market) => ({
      ...market,
      panelsSummary: indexedSummary[market.code],
    })),
  };
}

const getCurationStatusTotal = (
  curationStatus: UnPromisify<
    ReturnType<typeof getMarketPanelsSummary>
  >['getMarketPanelsSummary']['data'][number]['panelStatus'][number]['curationStatus'],
) =>
  curationStatus.reduce((acc2, cs) => {
    return {...acc2, [cs.type]: cs.total};
  }, {} as Record<typeof curationStatus[number]['type'], typeof curationStatus[number]['total']>);

const getActivePanelStatus = (
  panelStatus: UnPromisify<
    ReturnType<typeof getMarketPanelsSummary>
  >['getMarketPanelsSummary']['data'][number]['panelStatus'],
) => panelStatus.find((ps) => ps.type === PanelStatus.ACTIVE);
