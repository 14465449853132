import {combineReducers} from 'redux';
import {Action, createReducer} from 'redux-act';

import {Breadcrumb, MediaTypeCategory} from 'models';
import {SearchMarkets} from 'services/__generated__/SearchMarkets';

import {actions} from './actions';

export const initialState = {
  messages: {} as Record<React.Key, Message>,

  breadcrumbs: [] as Breadcrumb[],
  includeInactiveMarkets: false,
  loading: true,
  markets: [] as SearchMarkets['searchMarkets']['data'],
  allMarkets: [] as SearchMarkets['searchMarkets']['data'],
  mediaTypeCategories: [] as MediaTypeCategory[],
  mediaSynonyms: [] as string[],
  snackbarOpen: false,
  pageTitle: '',
};

type Dispatch = <P, M = unknown>(action: Action<P, M>) => void;
type MessageParam = {
  key: string;
  title: string | React.ReactElement<any>;
  action?: 'loading' | 'error' | 'refresh' | 'callback' | '';
  autoHideDuration?: number | null;
  dismissed?: boolean;

  callbackAction?: (dispatch: Dispatch) => void;
  callbackLabel?: string;
};
type Message = MessageParam & Required<Pick<MessageParam, 'key' | 'title'>>;

const breadcrumbs = createReducer(
  {
    [actions.setBreadcrumbs as any]: (state, payload) => [...payload],
  },
  initialState.breadcrumbs,
);

const includeInactiveMarkets = createReducer(
  {
    [actions.setIncludeInactiveMarkets as any]: (state, payload) => payload,
  },
  initialState.includeInactiveMarkets,
);

const loading = createReducer(
  {
    [actions.hideLoading as any]: (state) => false,
    [actions.showLoading as any]: (state) => true,
  },
  initialState.loading,
);

const markets = createReducer(
  {
    [actions.setMarkets as any]: (state, payload) => [...payload],
  },
  initialState.markets,
);

const allMarkets = createReducer({}, initialState.allMarkets).on(
  actions.setAllMarkets,
  (state, payload) => [...payload],
);

const mediaTypeCategories = createReducer({}, initialState.mediaTypeCategories).on(
  actions.setMediaTypeCategories,
  (state, payload) => payload,
);

const mediaSynonyms = createReducer({}, initialState.mediaSynonyms).on(
  actions.setMediaSynonyms,
  (state, payload) => payload,
);

const pageTitle = createReducer(
  {
    [actions.setPageTitle as any]: (state, payload) => payload,
  },
  initialState.pageTitle,
);

const showMessageReducer = (state: typeof initialState.messages, payload: Message) => {
  if (state[payload.key]) {
    return state;
  }
  const result = {
    ...state,
    [payload.key]: payload,
  };
  return result;
};

const messages = createReducer({}, initialState.messages)
  .on(actions.showMessageAutoHideOff, showMessageReducer)
  .on(actions.showMessage, showMessageReducer)
  .on(actions.hideMessage, (state, payload) => {
    if (payload?.key && state[payload.key] && state[payload.key].key) {
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          dismissed: true,
        },
      };
    }
    return state;
  })
  .on(actions.removeMessage, (state, payload) => {
    if (payload?.key && state[payload.key]) {
      return {
        ...state,
        [payload.key]: undefined,
      };
    }
    return state;
  });

export type AppState = typeof initialState;
export const app = combineReducers<AppState>({
  breadcrumbs,
  includeInactiveMarkets,
  loading,
  markets,
  messages,
  allMarkets,
  mediaTypeCategories,
  mediaSynonyms,
  pageTitle,
});
