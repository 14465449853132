import React, {FunctionComponent} from 'react';
import {
  filterColorChipStylesDecorator,
  MultipleCheckFilterChip,
  MultipleCheckFilterChipProps,
} from '@onsmart/ui-kit';

interface Props extends MultipleCheckFilterChipProps {}

const Chip: FunctionComponent<Props> = (props) => {
  return <MultipleCheckFilterChip {...props} />;
};

Chip.defaultProps = {
  chipPrefix: 'Origin:',
  templateLabel: (item) => item.toUpperCase(),
};

export default filterColorChipStylesDecorator(Chip);
