import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import {muiTheme} from 'utils/theme';

import * as serviceWorker from './serviceWorker';
import {QueryClientProvider} from 'react-query';
import { queryClient } from './services/graphql/CoreClient';

const {App} = require('components/App');
const {store} = require('config/redux');
const {history} = require('config');

const renderApp = (Component) => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={muiTheme}>
            <Component />
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    </QueryClientProvider>,
    document.getElementById('root'),
  );
};

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const newApp = require('./components/App').App;
    renderApp(newApp);
  });
}

renderApp(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
