import {FormatFilter, PrimeFilter, SearchTermFilter} from '@onsmart/ui-kit';
import {createReducer} from 'redux-act';

import AcceptableImageFilter from 'components/common/filters/AcceptableImageFilter';
import CurationStatusFilter from 'components/common/filters/CurationStatusFilter';
import DataOriginFilter, {DataOriginEnum} from 'components/common/filters/DataOriginFilter';
import InventoryIdFilter from 'components/common/filters/InventoryIdFilter';
import LocationFilter from 'components/common/filters/LocationFilter';
import MarketFilter from 'components/common/filters/MarketFilter';
import MediaTypeFilter from 'components/common/filters/MediaTypeFilter';
import StatusFilter from 'components/common/filters/StatusFilter';
import {CurationStatusEnum, LocationFilter as LocationFilterModel} from 'models';
import {Sorting} from 'models/ESSorting';
import {MediaFilter} from 'models/MediaType';
import {Panel, PanelFormat, Status} from 'models/Panel';

import {actions} from './actions';
import { IPanelList } from '../../../../services/PanelRestService';

const initialState = {
  loading: true,
  total: 0,
  data: [] as IPanelList['data'],
  pagination: {
    skip: 0,
    limit: 50,
    sort: {_id: 'asc'} as Sorting<Panel>,
  },
  filters: {
    [SearchTermFilter.id]: null as string,
    [DataOriginFilter.id]: null as DataOriginEnum[],
    [StatusFilter.id]: [Status.active],
    [CurationStatusFilter.id]: null as CurationStatusEnum[],
    [FormatFilter.id]: null as PanelFormat,
    [MarketFilter.id]: null as string[],
    [PrimeFilter.id]: null,
    [AcceptableImageFilter.id]: null as boolean,
    [InventoryIdFilter.id]: null as string[],
    [MediaTypeFilter.id]: null as MediaFilter,
    [LocationFilter.id]: null as LocationFilterModel,
  },
};

export type Filters = typeof initialState.filters;

export type InitialState = typeof initialState;

export default createReducer<InitialState>({}, initialState)
  // List
  .on(actions.fetchData, (state, payload) => ({
    ...state,
    loading: true,
  }))
  .on(actions.fetchDataSuccess, (state, {data, total}) => ({
    ...state,
    loading: false,
    data,
    total,
  }))
  .on(actions.fetchDataFail, (state) => ({
    ...state,
    loading: false,
  }))
  .on(actions.setPageSort, (state, sort) => ({
    ...state,
    loading: true,
    pagination: {
      ...state.pagination,
      sort,
    },
  }))
  .on(actions.setPageLimit, (state, limit) => ({
    ...state,
    loading: true,
    pagination: {
      ...state.pagination,
      skip: 0,
      limit,
    },
  }))
  .on(actions.setPageSkip, (state, skip) => ({
    ...state,
    loading: true,
    pagination: {
      ...state.pagination,
      skip,
    },
  }))
  .on(actions.resetFilters, (state) => ({
    ...state,
    loading: true,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
    filters: initialState.filters,
  }))
  .on(actions.setFilters, (state, filters) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
    loading: true,
    filters,
  }))
  .on(actions.concatFilters, (state, filters) => ({
    ...state,
    loading: true,
    filters: {
      ...state.filters,
      ...filters,
    },
  }));
