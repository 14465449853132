import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import {RootState} from 'config/redux/rootReducer';
import {Role} from 'models';

interface Props {
  requiredRole?: Role;
  hideForRole?: Role;
}

class HasRoleComponent extends Component<Props & {currentUserRole: Role[]}> {
  render() {
    const {children} = this.props;

    if (this.isAdmin() || this.authorized()) {
      return <div className="wrapper-has-role"> {children} </div>;
    }

    return null;
  }

  isAdmin() {
    return this.props.currentUserRole.includes(Role.admin);
  }

  authorized() {
    const {currentUserRole, requiredRole, hideForRole} = this.props;

    if (hideForRole) return !currentUserRole.includes(hideForRole);

    if (requiredRole) return currentUserRole.includes(requiredRole);

    return false;
  }
}

const getRoles = (state: RootState) => state.authorization?.user?.permissions?.roles ?? [];

export const getUserRoles = createSelector([getRoles], (roles) => roles);

const mapStateToProps = (state: RootState) => {
  return {
    currentUserRole: getUserRoles(state),
  };
};

export const HasRole = connect(mapStateToProps)(HasRoleComponent);
