import { get as _get, kebabCase } from 'lodash';

import {ActivityRestService} from '../../services/ActivityRestService';

const STORAGE_KEY = 'activity-queue-offline';
const summaryMessages = {
  curation: (data) => `Curation status was changed from ${kebabCase(data.o)} to ${kebabCase(data.n)}`,
  streetview: () => 'Street view snapshots captured',
  location: () => 'Location data changed',
};

export type TopicType = 'curation' | 'streetview' | 'location';
export type ActivityType = 'Update' | 'Create' | 'Delete';
export type ActivityData = string[] | {o: string; n: string};
export type ActivityModel = {
  id: string;
  name: string;
  type: string;
  locationUrl?: string;
};
export type ActivityInput = {
  data: ActivityData;
  model: ActivityModel;
};

type Storage = {[index: string]: any};

export class ActivityQueue {
  storage: Storage = {};
  private static instance: ActivityQueue;
  private activity: ActivityRestService;

  private constructor() {
    this.activity = ActivityRestService.getInstance();
    setTimeout(() => this.processOffline(), 1000);
    this.initEvent();
  }

  static getInstance() {
    return new ActivityQueue();
  }

  async processOffline() {
    let item = localStorage.getItem(STORAGE_KEY);
    if (!item) return Promise.resolve();

    let storage = JSON.parse(item);
    await Promise.all(
      Object.keys(storage).map((k) => this.createActivity(k, storage[k], 'Update')),
    );

    localStorage.removeItem(STORAGE_KEY);
  }

  initEvent() {
    window.addEventListener(
      'storage',
      (e) => {
        let isEnqueueEvent = e.key === STORAGE_KEY && e.newValue;
        if (isEnqueueEvent) {
          let tabId = Math.random().toString();

          localStorage.setItem(`${STORAGE_KEY}-key`, tabId);
          setTimeout(() => {
            let storageKey = localStorage.getItem(`${STORAGE_KEY}-key`);

            if (storageKey === tabId) {
              localStorage.removeItem(`${STORAGE_KEY}-key`);
              setTimeout(() => this.processOffline());
            }
          }, 1000);
        }
      },
      false,
    );

    window.addEventListener(
      'beforeunload',
      (e) => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this.storage));
      },
      false,
    );
  }

  combine(topic: string, oldData: ActivityInput, newData: ActivityInput) {
    return {
      model: newData.model,
      data:
        topic === 'curation'
          ? //concat the first old with the last new curationStastus
            {o: _get(oldData, 'data.o') || _get(newData, 'data.o'), n: _get(newData, 'data.n')}
          : //concat all images url sended
            ((_get(oldData, 'data') as any[]) || []).concat(_get(newData, 'data')),
    };
  }

  queue<T extends ActivityData>(topic: TopicType, data: T, model: any) {
    this.storage[topic] = this.combine(topic, this.storage[topic], {model, data});
  }

  createActivityByTopic(topic: string, type: ActivityType = 'Update') {
    const storage = this.storage[topic];
    this.createActivity(topic, storage, type);
  }

  createActivity(topic: string, input?: any, type: ActivityType = 'Update') {
    const {data, model} = input;
    const summary = summaryMessages[topic](data);

    const image = (Array.isArray(data) ? data : []).map((i) => {
      const url = new URL(i);
      url.searchParams.delete('key');
      
      return url.href;
    });

    const object = {
      id: model.id,
      type: model.type,
      name: model.name,
      url: model.locationUrl,
    };

    this.activity.create({type, summary, object, image});
    delete this.storage[topic];
  }
}

ActivityQueue.getInstance();
