import {SearchTermFilter} from '@onsmart/ui-kit';
import {createReducer} from 'redux-act';

import {InactiveMarketsFilter} from 'components/pages/Markets/Filters';
import {MarketWithAggs} from 'models';
import {Sorting} from 'models/ESSorting';

import {actions} from './actions';
import {updateData} from './utils';

const initialState = {
  loading: true,
  total: 0,
  allData: [] as MarketWithAggs[],
  data: [] as MarketWithAggs[],
  pagination: {
    skip: 0,
    limit: 1000,
    sort: {name: 'asc'} as Sorting<MarketWithAggs>,
  },
  filters: {
    [SearchTermFilter.id]: null as string,
    [InactiveMarketsFilter.id]: null as boolean,
  },
};

export type Filters = typeof initialState.filters;
export type InitialState = typeof initialState;

type Handler<S, P, M = {}> = (state: S, payload: P, meta?: M) => S;

const updateDataState = <P, M>(updateHandler: Handler<InitialState, P, M>) => (
  state: InitialState,
  payload: P,
  meta: M,
) => {
  const updatedState = updateHandler(state, payload, meta);
  const data = updateData(updatedState);
  return {...updatedState, data, total: data.length};
};

export default createReducer<InitialState>({}, initialState)
  // List
  .on(actions.fetchData, (state, payload) => ({
    ...state,
    loading: true,
  }))
  .on(
    actions.fetchDataSuccess,
    updateDataState((state, {data, total}) => ({
      ...state,
      loading: false,
      allData: data,
    })),
  )
  .on(actions.fetchDataFail, (state) => ({
    ...state,
    loading: false,
  }))
  .on(
    actions.setPageSort,
    updateDataState((state, sort) => ({
      ...state,
      pagination: {
        ...state.pagination,
        sort,
      },
    })),
  )
  .on(
    actions.resetFilters,
    updateDataState((state) => ({
      ...state,
      pagination: {
        ...state.pagination,
        skip: initialState.pagination.skip,
      },
      filters: initialState.filters,
    })),
  )
  .on(
    actions.setFilters,
    updateDataState((state, filters) => ({
      ...state,
      pagination: {
        ...state.pagination,
        skip: initialState.pagination.skip,
      },
      filters,
    })),
  )
  .on(
    actions.concatFilters,
    updateDataState((state, filters) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    })),
  );
