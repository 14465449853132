import {Query} from 'bkn-ui';
import {createAction} from 'redux-act';

import {Indexed, KeywordsPageQuery} from 'models';

import {Category, DefinitionsChange} from './reducers';

export const actions = {
  fetchCategory: createAction('keywords/FETCH_CATEGORY_REQUESTED'),
  fetchKeywordsList: createAction<string>('keywords/FETCH_KEYWORDS_LIST_REQUESTED'),
  fetchKeywordDefinition: createAction<string>('keywords/FETCH_KEYWORDS_DEFINITION_REQUESTED'),
  queryKeywords: createAction<KeywordsPageQuery>('keywords/QUERY_KEYWORDS'),
  setIncludeInactiveMarkets: createAction<boolean>('keywords/INCLUDE_INACTIVE_MARKETS_CHANGE'),
  setDefinitions: createAction<any>('keywords/SET_DEFINITIONS'),
  setKeywords: createAction<any>('keywords/SET_KEYWORDS'),
  setCategory: createAction<Category[]>('keywords/SET_CATEGORY'),
  setSelectedCategory: createAction<string>('keywords/SET_SELECTED_CATEGORY'),
  setSelectedKeyword: createAction<string>('keywords/SET_SELECTED_KEYWORD'),
  setQuery: createAction<Query>('keywords/QUERY_CHANGE'),
  setTotal: createAction<number>('keywords/TOTAL_CHANGE'),
  setSearchTerm: createAction<string>('keywords/SEARCH_TERM_CHANGE'),
  setAlreadyUsed: createAction<boolean>('keywords/SET_ALREADY_USED'),
  setSelectedUnsavedChanges: createAction<Indexed<DefinitionsChange>>(
    'keywords/SET_SELECTED_UNSAVED_CHANGES',
  ),
  onSaveKeyword: createAction<{change: Partial<DefinitionsChange>}>('keywords/SAVE_KEYWORD'),
  onCreateKeyword: createAction<{change: Partial<DefinitionsChange>; history: History}>(
    'keywords/CREATE_KEYWORD',
  ),
  deleteKeyword: createAction<string>('keywords/DELETE_KEYWORD'),
};
