export interface RoadPanel {
  id: string;
  data: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  center: {lat: number; lon: number};
  updatedAt?: string;
}

export const getEmptyRoad = (): RoadPanel => ({
  id: '',
  center: {lat: 0, lon: 0},
  data: {type: 'FeatureCollection', features: []},
});
