import {Action} from 'redux-act';
import {delay, Effect} from 'redux-saga';
import { CallEffectFn, select, call, put, fork, take, race } from 'redux-saga/effects';

import * as errorActions from 'config/redux/error/actions';
import {RootState} from 'config/redux/rootReducer';

type StripEffects<T> = T extends IterableIterator<infer E>
  ? E extends Effect
    ? never
    : E
  : never;

type DecideReturn<T> = T extends Promise<infer R>
  ? R // If it's a promise, return the promised type.
  : T extends IterableIterator<any>
  ? StripEffects<T> // If it's a generator, strip any effects to get the return type.
  : T; // Otherwise, it's a normal function and the return type is unaffected.

/** Get the return type of a function called by Saga */
export type SagaReturnType<T extends (...args: any[]) => any> = DecideReturn<
  ReturnType<T>
>;

export type Saga<T = unknown> = (action?: Action<T>) => IterableIterator<any>;
type Safe = <T>(saga: Saga<T>) => Saga<T>;

export const takeLeading = (patternOrChannel, saga, ...argv) => fork(function*() {
  while (true) {
    const action = yield take(patternOrChannel);
    yield call(saga, action, null, null, null, null, null, ...argv);
  }
});

export const debounce = (ms, pattern, task, ...args) => fork(function*() {
  while (true) {
    let action = yield take(pattern);

    while (true) {
      const { debounced, latestAction } = yield race({
        debounced: delay(ms),
        latestAction: take(pattern)
      });

      if (debounced) {
        yield fork(task, action, null, null, null, null,null, ...args);
        break
      }

      action = latestAction;
    }
  }
});

export const safe: Safe = (saga) =>
  function* (action: Action<any | unknown>) {
    try {
      return yield call(saga, action);
    } catch (error) {
      console.error('Sagas Error: ', error);
      yield put(errorActions.unhandledError(error));
    }
  };

export function* scheduleSagas(callback: () => CallEffectFn<any>, ms: number) {
  try {
    yield call(delay, ms);
    return yield callback();
  } catch (e) {
    return;
  }
}

export const getLoggedUserSaga = function* () {
  const user: RootState['authorization']['user'] = yield select<RootState>(
    (state) => state.authorization.user,
  );

  return user;
};
