import React, {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  AsyncCreatableAutocomplete,
  Autocomplete,
  AutocompleteSize,
  FilterActionPopper,
  MultiSelectList,
  PopperContentSection,
} from '@onsmart/ui-kit';
import equal from 'fast-deep-equal';
import * as _get from 'lodash.get';
import * as _isEmpty from 'lodash.isempty';

import {RawLocationType, ungroupedLocationTypes} from 'models/Location';
import {debounce} from 'utils/promise';

import {mapDispatchToProps, mapStateToProps} from './selectors';
import {styles} from './styles';

import type {AutocompleteProps, onCloseType, Option as SelectOption} from '@onsmart/ui-kit';
import type {LocationFilter} from 'models/Location';
import type {ExternalProps, MapDispatchToProps, MapStateToProps} from './selectors';
import type {Styles} from './styles';
export type Option = {value: string; label: string; id: string};

type CachedFilter = {
  [type: string]: {
    [value: string]: Option[];
  };
};

interface Props extends MapStateToProps, MapDispatchToProps, Styles {}

interface State {
  isMenuOpen: boolean;
  selected: LocationFilter;
  cachedValues: CachedFilter;
}

class LocationFilterPopper extends Component<Props, State> {
  private debouncedLoadMoreGroupedValues: (term: '') => Promise<Option[]>;
  private debouncedLoadMoreUngroupedValues: (term: '') => Promise<Option[]>;
  constructor(props: Props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      selected: props.value,
      cachedValues: null,
    };

    const loadMoreUngroupedValues = this.getLoadingFunction((response) => {
      const values = response.map((value) => value.key);

      const result: SelectOption[] = (values as unknown as string[]).map((value) => ({
        label: value,
        id: value,
        value,
      }));

      return result;
    });

    const loadMoreGroupedValues = this.getLoadingFunction((response) => {
      const {type = ''} = this.state.selected;
      const rawType = RawLocationType[type];

      const result = response.map((value) => ({
        label: value.key,
        options: value[rawType].buckets.map((bucket) => ({value: bucket.key, label: bucket.key})),
      }));

      return result;
    });

    this.debouncedLoadMoreUngroupedValues = debounce(loadMoreUngroupedValues, 500);
    this.debouncedLoadMoreGroupedValues = debounce(loadMoreGroupedValues, 500);
  }

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>,
    type?: onCloseType,
  ) => {
    if (type === 'away' && this.state.isMenuOpen) {
      return;
    }
    this.props.onPopperClose();
  };

  handleMenuOpen = () => {
    this.setState({isMenuOpen: true});
  };

  handleMenuClose = () => {
    this.setState({isMenuOpen: false});
  };

  handleSubmit = (ev: React.SyntheticEvent<Element, Event>) => {
    const {onChange} = this.props;
    const {selected} = this.state;
    setTimeout(() => onChange(selected, ev), 0);
  };

  handleTypeChange = (op: Required<AutocompleteProps>['options'][number]) => {
    this.setState((state) => ({
      selected: {
        ...state.selected,
        type: !!op ? op.value : null,
        values: [],
      },
    }));
  };

  handleValuesChange = (value: Option[]) => {
    const validValues = value.filter((m) => !_isEmpty(m));

    const values = validValues.map((m) => m.label);

    const valuesSet: {[key: string]: string} = values.reduce((curr, acc) => {
      curr[`${acc}`] = acc;
      return curr;
    }, {});

    const filteredValues = Object.values(valuesSet);

    this.setState((state) => ({
      selected: {...state.selected, values: filteredValues},
    }));
  };

  getLoadingFunction =
    (transformer: (response: any) => any) =>
    async (term: string = '') => {
      const handledTerm = !!term ? term : '_';
      const {cachedValues, selected} = this.state;
      const {type = ''} = selected;
      const cached = _get(cachedValues, `${type}.${handledTerm}`, []);

      if (!cached.length) {
        const response = await this.props.loadMore({
          type: type,
          value: term,
        });

        const result = transformer(response);

        this.setState({
          cachedValues: {
            ...(cachedValues || {}),
            [type]: {
              ...((cachedValues || {})[type] || {}),
              [handledTerm]: result,
            },
          },
        });

        return result;
      }

      return cached;
    };

  getHandlers = () => {
    const type = this.state.selected.type as string;

    if (!ungroupedLocationTypes.includes(type)) {
      return {
        loadOptions: this.debouncedLoadMoreGroupedValues,
      };
    } else {
      return {
        loadOptions: this.debouncedLoadMoreUngroupedValues,
      };
    }
  };

  componentWillReceiveProps(nextProps: Props) {
    if (!equal(nextProps.value, this.state.selected)) {
      this.setState({selected: nextProps.value});
    }
  }

  render() {
    const {anchorEl, open} = this.props;
    const {selected} = this.state;
    const {type} = selected || ({} as LocationFilter);
    const values = selected.values || [];

    const isSelectedEmpty = _isEmpty(type) || _isEmpty(values);

    return (
      <FilterActionPopper
        anchorEl={anchorEl}
        open={open}
        onClose={this.handleClose}
        headerTitle="Location"
        disabled={isSelectedEmpty}
        onSubmit={this.handleSubmit}
        {...({} as any)}
      >
        {this.renderContent()}
      </FilterActionPopper>
    );
  }

  renderContent = () => {
    const {typeOptions} = this.props;
    const {selected} = this.state;
    const {type} = selected || ({} as LocationFilter);
    const values = selected.values || [];

    const {loadOptions} = this.getHandlers();

    return (
      <>
        <PopperContentSection {...({} as any)}>
          <Autocomplete
            label="Type"
            size={AutocompleteSize.small}
            options={typeOptions}
            onMenuOpen={this.handleMenuOpen}
            onMenuClose={this.handleMenuClose}
            onChange={this.handleTypeChange}
            value={type ? {value: type, label: type} : null}
            placeholder=""
            isClearable
            autoFocus
          />
        </PopperContentSection>
        <MultiSelectList
          key={selected.type}
          placeholder=""
          label="Values"
          isDisabled={!selected.type}
          defaultOptions={!!selected.type}
          loadOptions={loadOptions}
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          onChange={this.handleValuesChange}
          values={values.map((value) => ({label: value, value}))}
          SelectComponent={AsyncCreatableAutocomplete}
          createOptionPosition="last"
        />
      </>
    );
  };
}

export default withStyles(styles)(
  connect<MapStateToProps, MapDispatchToProps, ExternalProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(LocationFilterPopper),
);
