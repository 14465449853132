import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import {getEmptyRoad, RoadPanel} from 'models';

import {actions} from './actions';
import {roadReducers} from './roadReducers';
import { IGetPanelResponse, ICreatePanelInput, IUpdatePanelInput } from '../../../../services/PanelRestService';

interface IPanelNavigation {
  [key: string]: {
    currentId: string;
    prevId?: string;
    nextId?: string;
  }
};

export interface IPanelInput extends Partial<ICreatePanelInput>, Partial<IUpdatePanelInput> {
  marketName?: string
  mapImage?: {
    url: string
  }
}

const empty_road = getEmptyRoad();

export const initialState = {
  panel: {} as IGetPanelResponse,
  panelInput: {} as IPanelInput,

  panelNavigationIndex: {} as IPanelNavigation,
  panelFormLoading: false,

  mapDialogOpen: false,

  curationRoadOpen: false,
  roadPanel: empty_road as RoadPanel,
  rawRoadPanel: empty_road as RoadPanel,
  initialRoadPanel: empty_road as RoadPanel,
};

// REDUCER
const panelNavigationIndex = createReducer({}, initialState.panelNavigationIndex)
  .on(actions.setPanelNavigationIndex, (_state, payload) => payload);

const mapDialogOpen = createReducer({}, initialState.mapDialogOpen).on(
  actions.toggleMapDialog,
  (state) => !state,
);

const panel = createReducer({}, initialState.panel)
  .on(actions.setPanel, (state, payload) => ({ ...payload }))
  .on(actions.setPanelInput, (state, payload) => ({ ...state, ...payload as IGetPanelResponse }))
  .on(actions.cleanPanelForm, () => ({ ...initialState.panel }));

const panelInput = createReducer({}, initialState.panelInput)
  .on(actions.setPanel, () => ({ ...initialState.panelInput }))
  .on(actions.setPanelInput, (state, payload) => ({ ...state, ...payload }))
  .on(actions.cleanPanelForm, () => ({ ...initialState.panelInput }));

const panelFormLoading = createReducer({}, initialState.panelFormLoading)
  .on(actions.showPanelFormLoading, () => true)
  .on(actions.hidePanelFormLoading, () => false);

const rawRoadPanel = createReducer({}, initialState.rawRoadPanel).on(
  actions.setRawRoad,
  (_state, payload) => payload,
);

const initialRoadPanel = createReducer({}, initialState.initialRoadPanel)
  .on(actions.setPanel, (state, {id}) => ({...state, id}))
  .on(actions.initCurationRoad, (state, payload) => payload)
  .on(actions.closeCurationRoad, ({id}) => ({...initialState.initialRoadPanel, id}));

const roadPanel = createReducer({}, initialState.roadPanel)
  .on(actions.setPanel, (state, {id}) => ({...state, id}))
  .on(actions.closeCurationRoad, ({id}) => ({...initialState.roadPanel, id}))
  .on(actions.initCurationRoad, (_, payload) => payload)
  .on(actions.setCurationRoad, (_, payload) => payload)

  .on(actions.updateRoadGeoJSON, roadReducers['updateRoadGeoJSON'])
  .on(actions.setAlertRoadPoint, roadReducers['setAlertRoadPoint'])
  .on(actions.removeAlertRoadPoint, roadReducers['removeAlertRoadPoint'])
  .on(actions.removeRoadPoint, roadReducers['removeRoadPoint'])
  .on(actions.reAddRoadPoint, roadReducers['reAddRoadPoint']);

const curationRoadOpen = createReducer({}, initialState.curationRoadOpen)
  .on(actions.closeCurationRoad, (_) => false)
  .on(actions.saveCurationRoad, (_) => false)
  .on(actions.openCurationRoad, (_) => true);

export type InitialState = typeof initialState;

export default combineReducers<InitialState>({
  panel,
  panelInput,
  panelNavigationIndex,
  panelFormLoading,
  mapDialogOpen,
  roadPanel,
  rawRoadPanel,
  curationRoadOpen,
  initialRoadPanel,
});
