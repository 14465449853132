import {
  FilterColorChip,
  FilterColorChipProps,
  filterColorChipStylesDecorator,
} from '@onsmart/ui-kit';

interface Props extends FilterColorChipProps<boolean> {}

class Chip extends FilterColorChip<Props, boolean> {
  getLabel(value: boolean) {
    return `Inactive Markets`;
  }
}

export default filterColorChipStylesDecorator(Chip);
