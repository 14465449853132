import {MediaType, MediaTypeCategory, MediaTypeSubcategory} from 'models';

export const getMediaTypesBySubcategory = (subcategory: MediaTypeSubcategory) =>
  subcategory ? subcategory.mediaTypes : ([] as MediaType[]);

export const getMediaTypesByCategory = (category: MediaTypeCategory) =>
  category ? category.subcategories.flatMap(getMediaTypesBySubcategory) : ([] as MediaType[]);

export const toOptions = (category: (MediaTypeCategory | MediaTypeSubcategory)[]) =>
  category
    .map((c) => ({label: c.name, value: c.name}))
    .sort((a, b) => a.label.localeCompare(b.label));
