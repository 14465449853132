import React from 'react';
import {BaseFilter, BaseFilterProps, RadioFilterPopper} from '@onsmart/ui-kit';
import isEqual from 'lodash-es/isEqual';

import Chip from './Chip';

export enum AcceptableImageEnum {
  yes = 'yes',
  no = 'no',
}

type Props = BaseFilterProps<boolean> & {
  options: AcceptableImageEnum[];
};
const FILTER_ID = 'acceptableImage' as const;

class AcceptableImageFilter extends BaseFilter<Props, boolean> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Acceptable Marketing Image',
    description: 'Show acceptable marketing image.',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: AcceptableImageFilter.defaultFilterAddOption,
    options: [AcceptableImageEnum.yes, AcceptableImageEnum.no],
  } as Partial<Props>;

  render() {
    const {value = '', context, isOpen: open, tempValue, initialValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;

    return (
      <div>
        {typeof value === 'boolean' && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value ? AcceptableImageEnum.yes : AcceptableImageEnum.no}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {open && (
          <RadioFilterPopper
            headerTitle={this.props.filterAddOption.title}
            options={this.props.options}
            open={open}
            anchorEl={anchorEl}
            value={tempValue || value}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={(v, e) => this.onChange(v === AcceptableImageEnum.yes, e)}
          />
        )}
      </div>
    );
  }
}

export default AcceptableImageFilter;
