import {History} from 'history';

import {RootState} from 'config/redux/rootReducer';

import {querySync} from '../../prism/lib';
import {actions} from '../actions';
import {UrlParams} from './';

export const acceptableImageValueToString = (
  acceptableImage: RootState['panels']['filters']['acceptableImage'],
) => {
  if (typeof acceptableImage === 'boolean') {
    return acceptableImage ? 'yes' : 'no';
  }
  return '';
};

export const acceptableImageQuerySync = (history: History<unknown>) =>
  querySync(UrlParams.acceptableImage, {
    history,
    selector: ({panels: {filters}}) => filters,
    action: actions.concatFilters,
    stringToValue: (s) => ({
      acceptableImage: s === 'yes' || s === 'no' ? s === 'yes' : null,
    }),
    valueToString: (v) => acceptableImageValueToString(v.acceptableImage),
    listeners: [actions.setFilters, actions.resetFilters],
    defaultValue: {
      acceptableImage: null,
    },
  });
