import React, {FunctionComponent} from 'react';
import {
  filterColorChipStylesDecorator,
  MultipleCheckFilterChip,
  MultipleCheckFilterChipProps,
} from '@onsmart/ui-kit';

interface Props extends MultipleCheckFilterChipProps {}

const Chip: FunctionComponent<Props> = (props) => {
  return <MultipleCheckFilterChip {...props} />;
};

Chip.defaultProps = {
  chipPrefix: 'Curation Status:',
};

export default filterColorChipStylesDecorator(Chip);
