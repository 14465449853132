import {History} from 'history';
import * as _isEmpty from 'lodash.isempty';

import {RootState} from 'config/redux/rootReducer';

import {querySync, stringTransforms} from '../../prism/lib';
import {actions} from '../actions';
import {Filters} from '../reducers';
import {join, split, UrlParams} from './';

export const locationValueToString = (media: RootState['panels']['filters']['media']) => {
  if (_isEmpty(media) || (!media.category && !media.subCategory && _isEmpty(media.names))) {
    return '';
  }

  return [
    stringTransforms.valueToString(media?.category || ''),
    stringTransforms.valueToString(media?.subCategory || ''),
    join(media?.names || []),
  ].join('^');
};

export const locationQuerySync = (history: History<unknown>) =>
  querySync(UrlParams.location, {
    history,
    selector: ({panels: {filters}}) => filters,
    action: actions.concatFilters,
    stringToValue: (s) => {
      if (!s) {
        return {location: null};
      }

      const [type, values] = split(s, '^');

      return {
        location: {
          type,
          values: split(values),
        } as Filters['location'],
      };
    },
    valueToString: (v) => {
      if (_isEmpty(v.location)) {
        return '';
      }
      return [
        stringTransforms.valueToString(v.location?.type || ''),
        join(v.location?.values || []),
      ].join('^');
    },
    listeners: [actions.setFilters, actions.resetFilters],
    defaultValue: {},
  });
