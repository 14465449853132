import {delay} from 'redux-saga';
import {call, put, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global/actions';
import {aggregation} from 'services/MarketService';
import {UnPromisify} from 'utils/promise';
import {safe} from 'utils/sagas';

import {actions} from './actions';

const fetchDataSaga = safe(function* () {
  //debounce
  yield call(delay, 500);
  yield put(globalActions.showLoading());

  try {
    const result: UnPromisify<ReturnType<typeof aggregation>> = yield call(aggregation);
    const {data, total} = result;

    yield put(actions.fetchDataSuccess({data, total}));
  } catch (err) {
    yield put(actions.fetchDataFail());
    yield put(globalActions.showMessage('💥 Unable to load markets'));
  } finally {
    yield put(globalActions.hideLoading());
  }
});

export const marketsPageSaga = function* () {
  yield takeLatest([actions.fetchData], fetchDataSaga);
};
