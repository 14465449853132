import * as _isEmpty from 'lodash.isempty';
import _omit from 'lodash.omit';
import {Action} from 'redux-act';
import {call, put, select, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global';
import {RootState} from 'config/redux/rootReducer';
import {KeywordRestService} from 'services';
import {addTotalToCategory, subtractTotalToCategory} from 'utils/models';

import {actions} from './actions';

function* fetchCategorySaga() {
  try {
    const response = yield KeywordRestService.getInstance().getAllCategories();
    const category = Object.keys(response).map((data) => {
      return {
        category: data,
        total: response[data],
      };
    });

    yield put(actions.setCategory(category));
    yield put(globalActions.hideLoading());
  } catch (e) {
    console.error(e);
    yield put(globalActions.showMessage('Error fetching categories'));
  }
}

function* fetchKeywordsSaga(action: Action<string>) {
  const category = action.payload;
  try {
    if (category) {
      yield put(actions.setSelectedCategory(category));
      const alreadyUsed: RootState['keywords']['alreadyUsed'] = yield select<RootState>(
        (state) => state.keywords.alreadyUsed,
      );
      const response = yield KeywordRestService.getInstance().getKeywords(category, alreadyUsed);
      yield put(actions.setKeywords(response));
    } else {
      yield put(actions.setSelectedCategory(''));
      yield put(actions.setKeywords([]));
    }
  } catch (e) {
    console.error(e);
    yield put(globalActions.showMessage('Error fetching keyword list'));
  }
}

function* fetchAlreadyUsedKeywords(action: Action<boolean>) {
  const alreadyUsed = action.payload;
  try {
    const category: RootState['keywords']['selectedCategory'] = yield select<RootState>(
      (state) => state.keywords.selectedCategory,
    );
    const response = yield KeywordRestService.getInstance().getKeywords(category, alreadyUsed);
    yield put(actions.setKeywords(response));
  } catch (e) {
    console.error(e);
    yield put(globalActions.showMessage('Error fetching used keywords'));
  }
}

function* fetchKeywordDefinitionSaga(action: Action<string>) {
  const keyword = action.payload;
  try {
    if (keyword) {
      yield put(actions.setSelectedKeyword(keyword));
      const response = yield KeywordRestService.getInstance().getDefinitions(keyword);
      const definitions = !_isEmpty(response) ? {id: keyword, ...response} : {};
      yield put(actions.setDefinitions(definitions));
    } else {
      yield put(actions.setDefinitions({}));
    }
  } catch (e) {
    console.error(e);
    yield put(globalActions.showMessage('Error fetching keyword'));
  }
}

function* handleKeywordsList(result: any, shouldFetchNewList: boolean) {
  const {keywords}: RootState['keywords'] = yield select<RootState>((state) => state.keywords);
  if (shouldFetchNewList) {
    yield put(actions.fetchKeywordsList(result.message.category));
  } else {
    yield put(actions.setKeywords(([...keywords, result.id] || []).sort()));
  }
}

function* isNewKeyword(result: any, isNew: boolean = false) {
  if (isNew) {
    const {category, keywords}: RootState['keywords'] = yield select<RootState>(
      (state) => state.keywords,
    );
    const selectedCategory: RootState['keywords']['selectedCategory'] = yield select<RootState>(
      (state) => state.keywords.selectedCategory,
    );
    const shouldFetchNewList = !keywords.length || selectedCategory !== result.message.category;

    yield call(handleKeywordsList, result, shouldFetchNewList);
    const addedCategory = addTotalToCategory(category, result.message.category);
    yield put(actions.setCategory(addedCategory));
  }
}
function* setSelected(result: any, history: any) {
  const unSavedChanges: RootState['keywords']['unSavedChanges'] = yield select<RootState>(
    (state) => state.keywords.unSavedChanges,
  );
  if (unSavedChanges) delete unSavedChanges[result.id];
  yield put(actions.setSelectedUnsavedChanges(unSavedChanges || {}));
  yield put(actions.setSelectedCategory(result.message.category || ''));
  yield put(actions.setSelectedKeyword(result.id || ''));
  if (history) history.push(`/keywords/${result.message.category}/${result.id}`);
}

function* updateDefinitions(result: any, history: History, message: string) {
  try {
    if (result.status && result.id) {
      yield call(isNewKeyword, result, !!history);
      yield call(setSelected, result, history);

      yield put(actions.setDefinitions({id: result.id, ...result.message}));
      yield put(globalActions.showMessage(`Keyword ${message}d`));
    } else {
      yield put(globalActions.showMessage(`Unable to ${message} keyword`));
    }
  } catch (error) {
    console.log(error);
    yield put(globalActions.showMessage(`Unable to ${message} keyword`));
  }
}

function* updateDefinitionSaga(action: Action<any>) {
  const service = KeywordRestService.getInstance();
  const {change, history} = action.payload;
  const keywordId = history ? '' : change.id;
  const message = history ? 'create' : 'save';
  try {
    const result = yield service.saveKeyword(_omit(change, ['id', 'hasUnsavedChanges']), keywordId);
    yield call(updateDefinitions, result, history, message);
  } catch (error) {
    console.log(error);
    yield put(globalActions.showMessage(`Unable to ${message} keyword`));
  }
}

function* saveKeywordDefinitionSaga(action: Action<any>) {
  try {
    yield call(updateDefinitionSaga, action);
  } catch (error) {
    console.log(error);
    yield put(globalActions.showMessage('Unable to save keyword'));
  }
}

function* createKeywordDefinitionSaga(action: Action<any>) {
  try {
    yield call(updateDefinitionSaga, action);
  } catch (error) {
    console.log(error);
    yield put(globalActions.showMessage('Unable to create keyword'));
  }
}

function* deleteKeyword(action: Action<any>) {
  const keywordId = action.payload;
  try {
    const response = yield KeywordRestService.getInstance().remove(keywordId);
    const keywords: RootState['keywords']['keywords'] = yield select<RootState>(
      (state) => state.keywords.keywords,
    );
    const category: RootState['keywords']['category'] = yield select<RootState>(
      (state) => state.keywords.category,
    );
    const definitions: RootState['keywords']['definitions'] = yield select<RootState>(
      (state) => state.keywords.definitions,
    );
    if (response && response.status) {
      yield put(globalActions.showMessage('Keyword removed'));
    } else {
      yield put(globalActions.showMessage('Unable to remove keyword'));
    }
    const filteredKeywords = keywords.filter((keyword) => keyword !== keywordId);
    yield put(actions.setKeywords(filteredKeywords));
    const subtractedCategory = subtractTotalToCategory(category, definitions.category);
    yield put(actions.setCategory(subtractedCategory));
    yield put(actions.setDefinitions({}));
  } catch (error) {
    console.error(error);
    yield put(globalActions.showMessage('Unable to remove keyword'));
  }
}

export function* keywordsPageSaga() {
  yield takeLatest(actions.fetchCategory, fetchCategorySaga);
  yield takeLatest(actions.fetchKeywordsList, fetchKeywordsSaga);
  yield takeLatest(actions.onSaveKeyword, saveKeywordDefinitionSaga);
  yield takeLatest(actions.onCreateKeyword, createKeywordDefinitionSaga);
  yield takeLatest(actions.setAlreadyUsed, fetchAlreadyUsedKeywords);
  yield takeLatest(actions.deleteKeyword, deleteKeyword);
  yield takeLatest(actions.fetchKeywordDefinition, fetchKeywordDefinitionSaga);
}
