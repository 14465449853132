import {camelCase} from 'lodash';

import {Asset, Image} from 'models';

import {Location} from './Location';

export interface Panel {
  _id: string;
  id: string;
  assets: Asset[];
  curationStatus: CurationStatusEnum;
  curationNotes?: string;
  digital: boolean;
  format: PanelFormat;
  images: Image[];
  inventoryNumber: string;
  location: Location;
  locationDescription: string;
  marketCode: string;
  marketName: string;
  mapImage: {url: string};
  mediaName: string;
  mediaCategory: string;
  mediaSubCategory: string;
  mediaNameSynonyms: string[];
  name: string;
  panelStatus: Status;
  prime: boolean;
  xUnit: string;
  taxonomyId: string;
  statusDate: string;
  updatedBy: string;
  updatedAt: Date;
  dataSource: string;
  dataOrigin: string;
  marketingImageUrl: string;
  stationId: string;
  marketingImageAcceptable?: boolean;
  size?: string;
  sideOfHighway?: string;
  facing?: string;
  gtReferenceNumber?: string;
}

export enum Status {
  active = 'Active',
  inactive = 'Inactive',
  unavailable = 'Unavailable',
}

export enum CurationStatusEnum {
  draft = 'draft',
  review = 'review',
  notFound = 'not-found',
  completed = 'completed',
  published = 'published',
  inactive = 'inactive',
  unavailable = 'unavailable',
  hold = 'hold',
}

export type PanelFormat = 'static' | 'digital';

export type PanelDistanceUnit = 'feet' | 'miles';

const parse = <TEnum extends Record<string, any>>(enumSrcObject: TEnum) => 
  (str: string): TEnum[keyof TEnum] => {
    if(!str) return undefined;

    const key = camelCase(str);
    const value = enumSrcObject[key];

    if(value) return value;

    throw new Error(`Unable to parse enum from ${key}`);
  };

export const PanelStatusUtil = {
  parse: parse(Status)
};

export const CurationStatusUtil = {
  parse: parse(CurationStatusEnum)
};
