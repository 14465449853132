import {createAction} from 'redux-act';

import {Breadcrumb, MediaCategorySubFilter, MediaTypeCategory} from 'models';

import {AppState} from './reducers';

export type MessagePayload = AppState['messages'][''];

export const actions = {
  fetchMarkets: createAction<boolean>('app/MARKETS_FETCH_REQUESTED'),
  fetchAllMarkets: createAction('app/ALL_MARKETS_FETCH_REQUESTED'),
  fetchSurfaceSearches: createAction('app/SURFACE_FILTERS_FETCH_REQUESTED'),
  fetchMediaTypeCategories: createAction('app/MEDIA_TYPE_CATEGORIES_FETCH_REQUESTED'),
  fetchSynonymsOptionsWithParams: createAction<MediaCategorySubFilter>(
    'app/MEDIA_SYNONYMS_FETCH_REQUESTED',
  ),
  hideLoading: createAction('app/HIDE_LOADING'),
  navigateToRoute: createAction<string>('app/ROUTE_CHANGE'),
  setBreadcrumbs: createAction<Breadcrumb[]>('app/BREADCRUMBS_CHANGE'),
  setIncludeInactiveMarkets: createAction<boolean>('app/INCLUDE_INACTIVE_MARKETS_CHANGE'),
  setMarkets: createAction<AppState['markets']>('app/MARKETS_FETCH_SUCCEEDED'),
  setAllMarkets: createAction<AppState['allMarkets']>('app/ALL_MARKETS_FETCH_SUCCEEDED'),
  setMediaTypeCategories: createAction<MediaTypeCategory[]>(
    'app/MEDIA_TYPE_CATEGORIES_FETCH_SUCCEEDED',
  ),
  setMediaSynonyms: createAction<string[]>('app/MEDIA_SYNONYMS_FETCH_SUCCEEDED'),
  setPageTitle: createAction<string>('app/PAGE_TITLE_CHANGE'),
  showLoading: createAction('app/SHOW_LOADING'),
  hideMessage: createAction<Pick<MessagePayload, 'key'>>('app/HIDE_MESSAGE'),
  removeMessage: createAction<Pick<MessagePayload, 'key'>>('app/REMOVE_MESSAGE'),
  showMessage: createAction('app/SHOW_MESSAGE', (message: MessagePayload | string) => {
    const defaultHideDuration = 6000;
    if (typeof message === 'string') {
      return {key: message, title: message, autoHideDuration: defaultHideDuration};
    }
    const autoHideDuration =
      message.autoHideDuration !== undefined ? message.autoHideDuration : defaultHideDuration;
    return {
      ...message,
      autoHideDuration,
    };
  }),
  showMessageAutoHideOff: createAction(
    'app/SHOW_MESSAGE_AUTO_HIDE_OFF',
    (message: MessagePayload) => {
      return {
        ...message,
        autoHideDuration: null,
      };
    },
  ),
};
