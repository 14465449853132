import {snakeCase} from 'lodash';

import {UnPromiseReturnType} from 'utils/promise';

import {AssetSortOption} from '../__generated__/globalTypes';
import {SortingKeys, SortingUtil} from '../models/ESSorting';
import {CurationStatusUtil, PanelStatusUtil} from '../models/Panel';
import {GetAssetWithParent, GetAssetWithParentVariables} from './__generated__/GetAssetWithParent';
import {SearchAssets, SearchAssetsVariables} from './__generated__/SearchAssets';
import {
  SearchAssetsSuggestions,
  SearchAssetsSuggestionsVariables,
} from './__generated__/SearchAssetsSuggestions';
import {
  SearchParentPanelAssetsStatus,
  SearchParentPanelAssetsStatusVariables,
} from './__generated__/SearchParentPanelAssetsStatus';
import {CoreClient, gql} from './graphql/CoreClient';

const GET_ASSET_DEBUG_VALUES_FRAGMENT = gql`
  fragment GetAssetDebugValues on Asset {
    acceptedFileExtensions
    acceptsRealTimeData
    alcoholTypeAllowed
    areaType {
      rural
      urban
    }
    cancellationClause
    comments1
    comments2
    computerName
    copyApproval
    copyChangeCharge
    createdAt
    curationNotes
    currentAdvertizerNameList
    currentContractNumberList
    dataOrigin
    digitalSize
    directionOfTraffic
    extensionAllowed
    facilityDescription
    flagmanRequired
    flipCount
    geopathId
    geopathImpressions18
    idSynonyms
    illuminated
    impressionsId
    imsUpdatedAt
    initialInstallIncluded
    installationCharge
    leaseNumber
    location {
      bearing
      blockGroupId
      cbsa
      censusTractId
      center {
        coordinates
      }
      centerGeoPoint {
        lat
        lon
      }
      country
      countryCode
      county
      crossStreet
      csa
      dma
      dmaCode
      dmaRank
      locality
      neighborhood
      place
      primaryStreet
      state
      stateCode
      # viewshed
      zip
    }
    loopLength
    marketingDescription
    marketingImageAcceptable
    marketingInfo
    materialName
    mediaCategory
    mediaNameSynonyms
    mediaSubCategory
    parRateNet
    rateCardNet
    permitNumber
    photoSheetUrl
    pixelHeight
    pixelWidth
    playTimeFrom
    playTimeTo
    primaryRead
    productionCharge
    productionForced
    productionMaterialCode
    progCPM
    progEnabled
    rateCardNet
    rideNumber
    secondaryRead
    sectorName
    snipeCharge
    specSheetUrl
    spotLength
    sqFootage
    structureType
    tabPanelId
    tertiaryRead
    totalDec
    triVision
    unitNumberDisplay
    updatedAt
    xUnit
  }
`;

const GET_ASSET_WITH_PARENT = gql`
query GetAssetWithParent($id: ID!) {
    getAsset(id: $id) {
      id
      idCache
      name
      panelStatus
      updatedBy
      mediaName
      gtReferenceNumber
      size
      marketName
      marketCode
      statusDate
      format
      inventoryNumber
      suffix
      prime
      locationDescription
      sideOfHighway
      facing
      directionFromCrossStreet
      distanceUnit
      curationStatus
      marketingImageUrl
      distanceFromCrossStreet
      mapImage {
        url
      }
      location {
        center {
          coordinates
          type
        }
        bearing
        primaryStreet
        crossStreet
      }
      panel {
        id
        panelStatus
        assets {
          id
          panelStatus
        }
      }
      ...GetAssetDebugValues
    }
  }
  ${GET_ASSET_DEBUG_VALUES_FRAGMENT}
`;

const SEARCH_ASSETS = gql`
  query SearchAssets(
    $sort: [AssetSortOption!] = [ID_ASC]
    $limit: Int
    $skip: Int
    $query: String
    $operator: QueryOperator = AND
  ) {
    searchAssets(sort: $sort, limit: $limit, skip: $skip, query: $query, operator: $operator) {
      total
      data {
        id
        panelStatus
        curationStatus
        updatedBy
        marketCode
        marketName
        mediaName
        inventoryNumber
        suffix
        locationDescription
        mapImage {
          url
        }
        dataOrigin
        updatedAt
      }
    }
  }
`;

const SEARCH_PARENT_PANEL_ASSETS_STATUS = gql`
  query SearchParentPanelAssetsStatus($sort: [AssetSortOption!], $limit: Int, $query: String) {
    searchAssets(sort: $sort, limit: $limit, query: $query) {
      total
      data {
        id
        panelStatus
      }
    }
  }
`;

const SEARCH_ASSET_ID_SUGGESTIONS = gql`
  query SearchAssetsSuggestions($sort: AssetSortOption = ID_ASC, $limit: Int, $query: String) {
    searchAssets(sort: [$sort], limit: $limit, query: $query) {
      total
      data {
        id
      }
    }
  }
`;

export async function getAssetWithParent(id: string) {
  const options = {
    cache: {
      key: 'getAssetWithParent',
      staleTime: 5 * 60000
    }
  };

  const {getAsset: response} = await CoreClient
    .getInstance()
    .request<GetAssetWithParent, GetAssetWithParentVariables>(
      GET_ASSET_WITH_PARENT, 
      {id},
      options
    );
  
  return response;
};

export type IGetAssetWithParentResponse = UnPromiseReturnType<typeof getAssetWithParent>;

export async function searchParentPanelAssetsStatus(panelId: string) {
  const params = {
    query: `${panelId}*`,
    limit: 50,
    sort: [AssetSortOption.ID_ASC],
  };

  const options = {
    cache: {
      key: 'searchParentPanelAssetsStatus',
      staleTime: 5 * 60000
    }
  };

  const {searchAssets: response} = await CoreClient.getInstance().request<
    SearchParentPanelAssetsStatus,
    SearchParentPanelAssetsStatusVariables
  >(
    SEARCH_PARENT_PANEL_ASSETS_STATUS, 
    params, 
    options
  );

  const data = response.data.map(({__typename, panelStatus, ...asset}) => ({
    ...asset,
    panelStatus: PanelStatusUtil.parse(panelStatus),
  }));

  return {
    total: response.total,
    data,
  };
};

export type ISearchParentPanelAssetsStatusResponse = UnPromiseReturnType<
  typeof searchParentPanelAssetsStatus
>;

type SortableColumns =
  | 'id'
  | 'marketName'
  | 'curationStatus'
  | 'panelStatus'
  | 'mediaName'
  | 'locationDescription'
  | 'updatedBy'
  | 'updatedAt'
  | 'createdAt';

interface ISearchAssetsProps {
  limit: number;
  skip: number;
  query: string;
  sort: SortingKeys<SortableColumns>;
}

export async function searchAssets(params: ISearchAssetsProps) {
  const sort = SortingUtil.toArray(params.sort).map<AssetSortOption>(
    (sortValue) => AssetSortOption[snakeCase(sortValue).toUpperCase()],
  );

  if (!params.sort.id) {
    sort.push(AssetSortOption.ID_ASC);
  }

  const internalParams = {
    ...(!!params.query && {query: params.query}),
    limit: params.limit,
    skip: params.skip,
    sort,
  };

  const options = {
    cache: {
      key: 'searchAssets',
      staleTime: 5 * 60000
    }
  };

  const {searchAssets: response} = await CoreClient
    .getInstance()
    .request<SearchAssets, SearchAssetsVariables>(
      SEARCH_ASSETS,
      internalParams,
      options
    );

  const data = response.data.map(({__typename, curationStatus, panelStatus, ...asset}) => ({
    ...asset,
    curationStatus: CurationStatusUtil.parse(curationStatus),
    panelStatus: PanelStatusUtil.parse(panelStatus),
  }));

  return {
    total: response.total,
    data,
  };
}

export type ISearchAssetsResponse = UnPromiseReturnType<typeof searchAssets>;

export async function searchSuggestions(searchTerm: string, limit = 20) {
  const params = {
    query: `idSynonyms:*${searchTerm}*`,
    limit,
  };

  const options = {
    cache: {
      key: 'searchSuggestions',
      staleTime: 5 * 60000
    }
  };

  const {searchAssets: response} = await CoreClient
    .getInstance()
    .request<SearchAssetsSuggestions, SearchAssetsSuggestionsVariables>(
      SEARCH_ASSET_ID_SUGGESTIONS,
      params,
      options
    );

  return response.data.map(({id}) => id);
}
