import type {
  FilterColorChipProps} from '@onsmart/ui-kit';
import {
  FilterColorChip,
  filterColorChipStylesDecorator,
} from '@onsmart/ui-kit';
import * as _isEmpty from 'lodash.isempty';

import type {LocationFilter} from 'models/Location';
import {truncateFilterLabel} from 'utils/filters';

interface Props extends FilterColorChipProps<LocationFilter> {}

const getValuesLabel = (value: LocationFilter) => {
  const {type, values} = value || ({} as LocationFilter);

  const typeLabel = type || '';
  return !!values && !!values.length ? truncateFilterLabel(typeLabel, values) : '';
};

class Chip extends FilterColorChip<Props, LocationFilter> {
  getLabel(value: LocationFilter) {
    if (_isEmpty(value?.values)) {
      return 'Location';
    } else {
      return getValuesLabel(value);
    }
  }
}

export default filterColorChipStylesDecorator(Chip);
