import _isEmpty from 'lodash.isempty';
import moment from 'moment';
import {call, put, select, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global/actions';
import {RootState} from 'config/redux/rootReducer';
import {LocationTypeWithoutText, Panel} from 'models';
import {PanelRestService, searchPanels, searchPanelsIds} from 'services';
import {FilterQueryRestService} from 'services/FilterQueryRestService';
import {downloadCSV} from 'utils/download';
import {getElasticSearchTerms} from 'utils/elasticSearch';

import {convertObjToElasticQueryString} from '../../../../utils/elasticSearch';
import {debounce, SagaReturnType} from '../../../../utils/sagas';
import {actions} from './actions';
import {fetchCSVPanelDataSource} from './utils';

const generateTerms = (condition: boolean, field: string, value: any) =>
  condition ? [{terms: {[field]: value}}] : [];
const generateTerm = (condition: boolean, field: string, value: any) =>
  condition ? [{term: {[field]: value}}] : [];

export const search = (
  source: string[],
  filters: RootState['panels']['filters'],
  pagination: RootState['panels']['pagination'],
  format: 'json' | 'csv' = 'json',
) => {
  const {limit, skip, sort} = pagination;
  const {
    searchTerm,
    curationStatus,
    market: marketCode,
    dataOrigin,
    panelStatus,
    media,
    location,
    inventory: ids,
    acceptableImage: marketingImageAcceptable,
  } = filters;

  const {category, subCategory, names} = media || ({} as typeof media);

  const marketingImageAcceptableFilter = generateTerm(
    typeof marketingImageAcceptable === 'boolean',
    'marketingImageAcceptable',
    marketingImageAcceptable,
  );
  const mediaCategoryFilter = generateTerm(!_isEmpty(category), 'mediaCategory', category);
  const mediaSubCategoryFilter = generateTerm(
    !_isEmpty(subCategory),
    'mediaSubCategory',
    subCategory,
  );

  const locationTypeKey = !location?.type ? null : LocationTypeWithoutText[location.type];

  const locationFilterTerm = generateTerms(
    !!locationTypeKey && !_isEmpty(location?.values),
    locationTypeKey,
    location?.values,
  );
  const mediaNameFilter = generateTerms(!_isEmpty(names), 'mediaNameSynonyms', names);
  const dataOriginFilter = generateTerms(!_isEmpty(dataOrigin), 'dataOrigin', dataOrigin);
  const marketCodeFilter = generateTerms(!_isEmpty(marketCode), 'marketCode', marketCode);
  const idsFilter = generateTerms(!_isEmpty(ids), '_id', ids);
  const panelStatusFilter = generateTerms(!_isEmpty(panelStatus), 'panelStatus', panelStatus);
  const curationStatusFilter = generateTerms(
    !_isEmpty(curationStatus),
    'curationStatus',
    curationStatus,
  );

  const terms = getElasticSearchTerms(filters, ['format', 'prime']);

  const elasticQuery = {
    query: {
      bool: {
        must: [
          {
            query_string: {
              query: searchTerm ? searchTerm : '*',
              default_operator: 'and',
            },
          },
          ...terms,
          ...mediaCategoryFilter,
          ...mediaSubCategoryFilter,
          ...mediaNameFilter,
          ...dataOriginFilter,
          ...marketCodeFilter,
          ...idsFilter,
          ...locationFilterTerm,
          ...panelStatusFilter,
          ...curationStatusFilter,
          ...marketingImageAcceptableFilter,
        ],
      },
    },
    size: limit,
    from: skip,
    format,
    sort: {...sort, ...(!sort['_id'] ? {_id: 'asc'} : {})},
    _source: source,
  };

  return PanelRestService.getInstance().search<Panel>(elasticQuery);
};

function selectListFilterParams(state: RootState) {
  const {pagination, filters} = state.panels;

  return parseFilterToQueryParams(filters, pagination);
}

function parseFilterToQueryParams(
  filters: RootState['panels']['filters'],
  pagination: RootState['panels']['pagination'],
) {
  const {
    searchTerm,
    curationStatus,
    market: marketCode,
    dataOrigin,
    panelStatus,
    media,
    location,
    inventory: ids,
    acceptableImage: marketingImageAcceptable,
    prime,
    format,
  } = filters;

  const locationValue = location?.type
    ? {[LocationTypeWithoutText[location?.type]]: location?.values}
    : {};

  const queryFilters = {
    ...locationValue,
    curationStatus,
    marketCode,
    dataOrigin,
    panelStatus,
    _id: ids,
    marketingImageAcceptable,
    media,
    prime,
    format,
  };

  const FILTER_KEY_TO_ELASTIC_QUERY_KEY_DICTIONARY = {
    'media.category': 'mediaCategory',
    'media.subCategory': 'mediaSubCategory',
    'media.names': 'mediaNameSynonyms',
  };

  const query = convertObjToElasticQueryString({
    elasticKeyDictionary: FILTER_KEY_TO_ELASTIC_QUERY_KEY_DICTIONARY,
    obj: queryFilters,
    rawQueryString: searchTerm,
  });

  return {
    limit: pagination.limit,
    skip: pagination.skip,
    sort: pagination.sort,
    query,
  };
}

function* fetchDataSaga() {
  yield put(globalActions.showLoading());

  try {
    const queryParams: ReturnType<typeof selectListFilterParams> = yield select(
      selectListFilterParams,
    );
    const {data, total}: SagaReturnType<typeof searchPanels> = yield call(
      searchPanels,
      queryParams,
    );

    yield put(actions.fetchDataSuccess({data, total}));
  } catch (err) {
    yield put(actions.fetchDataFail());
    yield put(globalActions.showMessage('💥 Unable to load surfaces'));
  } finally {
    yield put(globalActions.hideLoading());
  }
}

function* fetchCSVtoDownload() {
  const message = {
    key: 'download',
    title: 'Requesting CSV. Please wait',
    action: 'loading' as const,
  };
  try {
    yield put(globalActions.showLoading());
    yield put(globalActions.showMessageAutoHideOff(message));

    const queryParams: ReturnType<typeof selectListFilterParams> = yield select(
      selectListFilterParams,
    );

    // Get Panel ids from applied filters
    const {data}: SagaReturnType<typeof searchPanelsIds> = yield call(searchPanelsIds, {
      ...queryParams,
      skip: 0,
      limit: 10000,
    });

    // Request csv from query-api creating an inventory filter.
    const csvResponse = yield call(() =>
      FilterQueryRestService.getInstance().queryCSV(
        data.map((p) => p.id),
        fetchCSVPanelDataSource,
      ),
    );

    // get first line(header) and replace the "mad." prefix.
    const parsedCsvResponse = csvResponse.replace(/^.*/, (m) => {
      return m.replace(/mad\./g, '');
    });

    const now = moment(new Date()).format('YYYYMMDDHHmmss');
    yield downloadCSV(`mad-inventory-grid-${now}`, parsedCsvResponse);
  } catch (e) {
    yield put(globalActions.showMessage('Unable to generate report.'));
    console.error(e);
  } finally {
    yield put(globalActions.hideMessage(message));
    yield put(globalActions.hideLoading());
  }
}

export const panelsPageSaga = function* () {
  yield debounce(
    500,
    [
      actions.fetchData,
      actions.setPageSort,
      actions.setPageLimit,
      actions.setPageSkip,
      actions.setFilters,
      actions.resetFilters,
    ],
    fetchDataSaga,
  );
  yield takeLatest(actions.downloadCSV, fetchCSVtoDownload);
};
