import React, {FunctionComponent} from 'react';
import {
  filterColorChipStylesDecorator,
  RadioFilterChip,
  RadioFilterChipProps,
} from '@onsmart/ui-kit';

interface Props extends RadioFilterChipProps {}

const Chip: FunctionComponent<Props> = (props) => {
  return <RadioFilterChip {...props} />;
};

Chip.defaultProps = {
  chipPrefix: 'Acceptable Marketing Image:',
};

export default filterColorChipStylesDecorator(Chip);
