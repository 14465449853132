import {History} from 'history';
import {all} from 'redux-saga/effects';

import {querySync, stringTransforms} from 'config/redux/modules/prism/lib';

import {actions} from '../actions';

import type {InitialState} from '../reducers';

export default function* init(history: History<unknown>) {
  yield all([
    querySync('$sort', {
      history,
      selector: ({markets: {pagination}}) => pagination.sort,
      action: actions.setPageSort,
      stringToValue: (s) => {
        let key = s;
        let value = 'asc';

        if (s.startsWith('-')) {
          key = s.replace('-', '');
          value = 'desc';
        }
        return {
          [key]: value,
        } as InitialState['pagination']['sort'];
      },
      valueToString: (v) => {
        const key = Object.keys(v)[0];
        return `${v[key] === 'desc' ? '-' : ''}${key}`;
      },
    }),
    querySync('searchTerm', {
      history,
      selector: ({markets: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        searchTerm: stringTransforms.stringToValue(s),
      }),
      valueToString: (v) => stringTransforms.valueToString(v.searchTerm),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync('includeInactiveMarkets', {
      history,
      selector: ({markets: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        inactiveMarkets: s === 'yes',
      }),
      valueToString: (v) => (v.inactiveMarkets ? 'yes' : ''),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
  ]);
}
