import {Category} from 'config/redux/modules/keywords/reducers';

export const typeOptions = [
  {value: 'audience', label: 'audience'},
  {value: 'demographic', label: 'demographic'},
  {value: 'behavioral', label: 'behavioral'},
];

export const categoryToOptions = (array: Category[]) =>
  (array || [])
    .map((c) => ({label: c.category, value: c.category}))
    .sort((a, b) => (a.label || '').localeCompare(b.label || ''));

const removeCategoryFromArray = (array: Category[], category: string) => {
  let oldTotal = 0;
  const removedCategory = (array || []).filter((c) => {
    if (c.category === category) {
      oldTotal = c.total;
      return false;
    }
    return true;
  });
  return {removedCategory, oldTotal};
};

export const addTotalToCategory = (array: Category[], category: string) => {
  const {removedCategory, oldTotal} = removeCategoryFromArray(array, category);
  const newCategory = [...removedCategory, {category: category, total: oldTotal + 1}];
  return newCategory.sort((a, b) => b.total - a.total);
};

export const subtractTotalToCategory = (array: Category[], category: string) => {
  const {removedCategory, oldTotal} = removeCategoryFromArray(array, category);
  const newCategory = [...removedCategory, {category: category, total: oldTotal - 1}];
  return newCategory.sort((a, b) => b.total - a.total);
};

export const keywordsToOptions = (array: string[]) =>
  (array || []).map((c) => ({label: c, value: c})).sort((a, b) => a.label.localeCompare(b.label));
