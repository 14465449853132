import {Query} from 'bkn-ui';
import {combineReducers} from 'redux';
import {Action, createAction, createReducer} from 'redux-act';
import {delay} from 'redux-saga';
import {call, put, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global';
import {ActivitiesPageQuery, Activity} from 'models';
import {ActivityRestService} from 'services';
import {convertToElasticSortObject} from 'utils/elasticSearch';
import {getPrefix} from 'utils/url';

// ACTIONS

export const actions = {
  fetchInitialData: createAction<ActivitiesPageQuery>('activities/INITIAL_FETCH_REQUESTED'),
  fetchActivities: createAction<ActivitiesPageQuery>('activities/ACTIVITIES_FETCH_REQUESTED'),
  setActivities: createAction<Activity[]>('activities/ACTIVITIES_FETCH_SUCCEEDED'),
  setQuery: createAction<Query>('activities/QUERY_CHANGE'),
  setSearchTerm: createAction<string>('activities/SEARCH_TERM_CHANGE'),
  setTotal: createAction<number>('activities/TOTAL_CHANGE'),
};

// REDUCER

export const initialState = {
  activities: [] as Activity[],
  query: {
    limit: 50,
    skip: 0,
    sort: '-published',
  },
  searchTerm: '',
  total: 0,
};

const activities = createReducer(
  {
    [actions.setActivities as any]: (state, payload) => [...payload],
  },
  initialState.activities,
);

const query = createReducer(
  {
    [actions.setQuery as any]: (state, payload) => ({...payload}),
  },
  initialState.query,
);

const searchTerm = createReducer(
  {
    [actions.setSearchTerm as any]: (state, payload) => payload,
  },
  initialState.searchTerm,
);

const total = createReducer(
  {
    [actions.setTotal as any]: (state, payload) => payload,
  },
  initialState.total,
);

type State = typeof initialState;

export default combineReducers<State>({
  activities,
  query,
  searchTerm,
  total,
});

// SAGAS

const fetchActivitiesData = (activitiesQuery: ActivitiesPageQuery) => {
  const {query, searchTerm} = activitiesQuery;

  const sort = [] as {[index: string]: string}[];
  if (query.sort) {
    const sortObj = convertToElasticSortObject(query.sort);
    sort.push(sortObj);
  }

  const prefix = getPrefix() ? 'stg.' : '';

  const elastic = {
    size: query.limit,
    from: query.skip,
    _source: ['published', 'actor.name', 'actor.email', 'summary', 'object'],
    sort,
    query: {
      bool: {
        must: [
          {
            query_string: {
              query: searchTerm ? searchTerm : '*',
              default_operator: 'and',
            },
          },
          {
            terms: {
              'object.type': ['panel', 'asset', 'market'],
            },
          },
          {
            regexp: {
              'object.url': `${prefix}(mad|assets).*`,
            },
          },
        ],
      },
    },
  };

  return ActivityRestService.getInstance().search(elastic);
};

function* fetchActivitiesSaga(action: Action<ActivitiesPageQuery>) {
  const {query, searchTerm} = action.payload;

  try {
    yield put(actions.setQuery(query));
    yield put(actions.setSearchTerm(searchTerm));

    yield call(delay, 500);

    yield put(globalActions.showLoading());

    const response = yield call(fetchActivitiesData, action.payload);

    const {data, total} = response;
    const activities = data;

    yield put(actions.setActivities(activities));
    yield put(actions.setTotal(total));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(globalActions.hideLoading());
  }
}

function* fetchInitialDataSaga(action: Action<ActivitiesPageQuery>) {
  const {query, searchTerm} = action.payload;

  try {
    yield put(globalActions.showLoading());

    yield put(actions.setQuery(query));
    yield put(actions.setSearchTerm(searchTerm));

    const activityResponse = yield call(fetchActivitiesData, action.payload);

    const {data, total} = activityResponse;
    const activities = data;

    yield put(actions.setActivities(activities));
    yield put(actions.setTotal(total));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(globalActions.hideLoading());
  }
}

export function* activitiesPageSaga() {
  yield takeLatest(actions.fetchActivities, fetchActivitiesSaga);
  yield takeLatest(actions.fetchInitialData, fetchInitialDataSaga);
}
