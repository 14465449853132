import {Role} from 'models';

import {CurationStatus as CurationStatusGraphqlEnum} from '../../__generated__/globalTypes';

const CURATOR = [
  CurationStatusGraphqlEnum.DRAFT,
  CurationStatusGraphqlEnum.NOT_FOUND,
  CurationStatusGraphqlEnum.REVIEW,
  CurationStatusGraphqlEnum.HOLD
];
const EDITOR = [
  ...CURATOR, 
  CurationStatusGraphqlEnum.COMPLETED
];
const PUBLISHER = [
  ...EDITOR,
  CurationStatusGraphqlEnum.PUBLISHED,
  CurationStatusGraphqlEnum.INACTIVE,
  CurationStatusGraphqlEnum.UNAVAILABLE
];
const ADMIN = PUBLISHER;

const roles = {
  [Role.admin]: ADMIN,
  [Role.madCurator]: CURATOR,
  [Role.madEditor]: EDITOR,
  [Role.madPublisher]: PUBLISHER,
};

const getRole = (userRoles: Role[]) =>
  [Role.admin, Role.madPublisher, Role.madEditor, Role.madCurator].find((role) =>
    userRoles.includes(role),
  );

export const getCurationStatusOptions = (userRoles: Role[]) => {
  const role = getRole(userRoles);
  return role ? roles[role] : [];
};
