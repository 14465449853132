import React from 'react';
import {BaseFilter, BaseFilterProps} from '@onsmart/ui-kit';

import Chip from './Chip';

type Props = BaseFilterProps<boolean>;
const FILTER_ID = 'inactiveMarkets' as const;

class InactiveMarketsFilter extends BaseFilter<Props, boolean> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Inactive Markets',
    description: 'Filter by inactive markets.',
    initialValue: true,
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: InactiveMarketsFilter.defaultFilterAddOption,
  } as Partial<Props>;

  render() {
    const {disabled} = this.context;
    const {value, initialValue} = this;

    return (
      <div>
        {value && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            enableColor={this.hasInitialFilters}
            hasChanges={value !== initialValue}
          />
        )}
      </div>
    );
  }
}

export default InactiveMarketsFilter;
