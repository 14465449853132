import * as React from 'react';
import {BaseFilter} from '@onsmart/ui-kit';
import * as _isEmpty from 'lodash.isempty';
import * as isEqual from 'lodash.isequal';

import {LocationType} from 'models/Location';

import Chip from './Chip';
import {Popper} from './Popper';

import type {BaseFilterProps} from '@onsmart/ui-kit';
import type {LocationFilter} from 'models/Location';
export type LocationFilterProps = BaseFilterProps<LocationFilter>;
const FILTER_ID = 'location' as const;

class Filter extends BaseFilter<LocationFilterProps, LocationFilter> {
  static id = FILTER_ID;

  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Location',
    description: 'Filter by locality, zip code, and more.',
  };

  static defaultProps: Partial<LocationFilterProps> = {
    ...BaseFilter.defaultProps,
    filterAddOption: Filter.defaultFilterAddOption,
  };

  handleSetTempValue = (value: LocationFilter) => {
    this.context.handleSetTempValue(FILTER_ID, value);
  };

  render() {
    const {value, initialValue, isOpen, context, tempValue} = this;
    const {anchorEl, disabled} = context;

    const isLoaded = !disabled;

    return (
      <div>
        {!_isEmpty(value) && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={isLoaded}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {isOpen && (
          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            value={tempValue || value || {type: LocationType['location.zip'], values: []}}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            onChange={this.onChange}
            resetTempValue={this.resetTempValue}
          />
        )}
      </div>
    );
  }
}

export default Filter;
