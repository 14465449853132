import {featureCollection, point, polygon} from '@turf/helpers';

import {environment} from 'config';
import {RoadPanel} from 'models';

import {RestService} from './RestService';

interface IPanelProps {
  id: string
  location: {
    bearing: number
    center: {
      type: string
      coordinates: GeoJSON.Point['coordinates']
    }
    viewshed: GeoJSON.Polygon | GeoJSON.MultiPolygon
  }
}

export class RoadPanelService extends RestService<any> {
  private static instance: RoadPanelService;

  private constructor() {
    super({
      baseUrl: environment.settings.apiUrl.road,
      apiUrl: '/',
    });
  }

  static getInstance() {
    return new RoadPanelService();
  }

  private emptyRoad = ({id, location}: IPanelProps): RoadPanel => {
    const [lon, lat] = location.center.coordinates;
    const viewshed = this.getPolygonViewshed(location.viewshed);

    return {
      id,
      data: featureCollection([
        point(location.center.coordinates, {type: 'panel', id: id, bearing: location.bearing}),
        polygon(viewshed.coordinates, {type: 'viewshed'}),
      ]),
      center: {lat, lon},
    };
  };

  getRawData = async (panel: IPanelProps) => {
    try {
      const resp = await this.axiosInstance.get(`/raw/${panel.id}`);
      return this.parsePanel(resp.data, panel);
    } catch (err) {
      if (err.response.status === 404) {
        return this.parsePanel(this.emptyRoad(panel), panel);
      }
      throw err;
    }
  };

  getCurated = async (panel: IPanelProps) => {
    try {
      const resp = await this.axiosInstance.get(`/curated/${panel.id}`);
      return this.parsePanel(resp.data, panel);
    } catch (err) {
      if (err.response.status === 404) {
        return null;
      }
      throw err;
    }
  };

  updateCurated = async (road: RoadPanel) => {
    // console.log('DEBUG updateCurated', road);
    return this.axiosInstance.patch(`/curated/${road.id}`, road);
  };

  createCurated = async (road: RoadPanel) => {
    // console.log('DEBUG createCurated', road);
    return this.axiosInstance.post('/curated', road);
  };

  private parsePanel = (road: RoadPanel, panel: IPanelProps): RoadPanel => {
    const [lon, lat] = panel.location.center.coordinates;
    road.center = {lat, lon};

    road.data.features.forEach((feat) => {
      const type = feat.properties!.type;
      if (type === 'panel') {
        feat.properties = {
          ...feat.properties,
          bearing: panel.location.bearing,
        };
      } else if (type === 'viewshed') {
        const viewshed = this.getPolygonViewshed(panel.location.viewshed);
        feat.geometry = polygon(viewshed.coordinates).geometry;
      }
    });

    return road;
  };

  getPolygonViewshed = (viewshed: GeoJSON.Polygon | GeoJSON.MultiPolygon): GeoJSON.Polygon => ({
    type: 'Polygon',
    coordinates: viewshed.type === 'MultiPolygon' ? viewshed.coordinates[0] : viewshed.coordinates
  });
}
