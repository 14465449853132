export const wait = (time: number = 50) =>
  new Promise((resolve) => setTimeout(() => resolve(), time));

export const debounce = <Fn extends (...args: unknown[]) => unknown>(inner: Fn, ms = 0) => {
  let timer = null;
  let resolves: ((a: unknown) => void)[] = [];

  return function (...args: Parameters<Fn>) {
    // Run the function after a certain amount of time
    clearTimeout(timer);
    timer = setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      const result = inner(...args) as UnPromisify<ReturnType<Fn>>;
      resolves.forEach((r) => r(result));
      resolves = [];
    }, ms);

    return new Promise<UnPromisify<ReturnType<Fn>>>((r) => resolves.push(r));
  };
};

export type UnPromisify<T> = T extends Promise<infer U> ? U : T;
export type UnPromiseReturnType<TType extends (...args: any) => any> = UnPromisify<
  ReturnType<TType>
>;
