import {createAction} from 'redux-act';

import {InitialState} from './reducers';

export const actions = {
  fetchData: createAction('inventory/FETCH_DATA'),
  fetchDataSuccess: createAction<{data: InitialState['data']; total: InitialState['total']}>(
    'inventory/FETCH_DATA_SUCCESS',
  ),
  fetchDataFail: createAction('inventory/FETCH_DATA_FAIL'),

  setPageSort: createAction<InitialState['pagination']['sort']>('inventory/SET_SORT'),
  setPageLimit: createAction<InitialState['pagination']['limit']>('inventory/SET_PAGE_LIMIT'),
  setPageSkip: createAction<InitialState['pagination']['skip']>('inventory/SET_PAGE_SKIP'),
  setFilters: createAction<InitialState['filters']>('inventory/SET_FILTERS'),
  concatFilters: createAction<Partial<InitialState['filters']>>('inventory/CONCAT_FILTERS'),
  resetFilters: createAction('inventory/RESET_FILTERS'),
};
