import {environment} from 'config';

import {RestService} from './RestService';
import { PanelStatusUtil } from '../models/Panel';

interface MediaName {
  count: number;
  name: string;
};

interface GetSynonymsParams {
  panelStatus?: string
  mediaCategory?: string
  mediaSubCategory?: string
  field?: string
};

interface GetSynonymsResponse {
  data: {
    name: string
  }[]
};

interface FetchMediaNamesResponse {
  data: MediaName[]
  total: number
};

export class MediaTypeRestService extends RestService<MediaName> {
  private static instance: MediaTypeRestService;

  private constructor() {
    super({
      baseUrl: environment.settings.apiUrl.imsMedia,
      apiUrl: '',
    });
  }

  static getInstance() {
    return new MediaTypeRestService();
  }

  public async fetchMediaNames() {
    const {imsMediaParams: name} = environment.settings.apiUrl;
    const url = name ? `?name=${name}` : '';
    
    const { data } = await this.axiosInstance.get<FetchMediaNamesResponse>(url);
    
    return data;
  };

  public async getSynonymsOptions({
    mediaCategory,
    mediaSubCategory,
    panelStatus = 'Active',
    field = 'mediaNameSynonyms',
  }: GetSynonymsParams = {}) {
    const params = {
      $field: field,
      panelStatus: PanelStatusUtil.parse(panelStatus),
      ...(mediaCategory && { mediaCategory }),
      ...(mediaSubCategory && { mediaSubCategory })
    };

    const response = await this.axiosInstance.get<GetSynonymsResponse>('', { params });
    const { data = [] } = response.data;
    const mediaSynonyms = data.map(({name}) => name);

    return mediaSynonyms;
  };
}
