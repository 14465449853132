import {RootState} from 'config/redux/rootReducer';
import {LocationFilter, LocationFilterQuery, LocationType} from 'models';
import {PanelRestService} from 'services';
import {toOptions} from 'utils/location';

import type {LocationFilterProps} from '../LocationFilter';

const getTypeOptions = toOptions(Object.values(LocationType));

export type ExternalProps = Pick<LocationFilterProps, 'onChange'> & {
  value: LocationFilter;
  onPopperClose: (temp?: LocationFilter) => void;
  open: boolean;
  anchorEl: HTMLElement;
  hasChanges: boolean;
  resetTempValue: () => void;
};

export const mapStateToProps = (state: RootState, external: ExternalProps) => ({
  ...external,
  typeOptions: getTypeOptions,
});

export const mapDispatchToProps = (dispatch) => ({
  loadMore: (query: LocationFilterQuery) => {
    const {type, value} = query;
    return PanelRestService.getInstance().findLocationSuggestions(type, value);
  },
});

export type MapStateToProps = ReturnType<typeof mapStateToProps>;
export type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
