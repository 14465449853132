import {environment} from 'config';
import {Activity} from 'models';

import {RestService} from './RestService';

export class ActivityRestService extends RestService<Activity> {
  private static instance: ActivityRestService;

  private constructor() {
    super({
      baseUrl: environment.settings.apiUrl.activity,
      apiUrl: '',
    });
  }

  static getInstance() {
    return new ActivityRestService();
  }
}
