import React from 'react';
import {FunctionComponent} from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

const OutfrontLogo: FunctionComponent<Props> = (props) => <svg {...props} />;

OutfrontLogo.defaultProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  version: '1.1',
  id: 'Layer_1',
  width: '140',
  height: '54',
  x: '0',
  y: '0',
  viewBox: '0 0 271 61',
  xmlSpace: 'preserve',
  children: (
    <>
      <script />
      <path
        d="M215.066 0.983887V20.6066L201.136 0.983887H192.898V35.1461H201.136V14.8127L215.066 35.1461H223.304V9.18281H232.193V35.1461H240.323V9.18281H250.675L254.306 0.983887H215.066Z"
        fill="black"
      />
      <path
        d="M173.657 0C163.738 0 155.662 8.03495 155.662 17.9283C155.662 27.8217 163.738 35.8566 173.657 35.8566C183.575 35.8566 191.651 27.8217 191.651 17.9283C191.651 8.03495 183.575 0 173.657 0ZM173.657 27.5484C168.345 27.5484 164.009 23.2303 164.009 17.9283C164.009 12.6263 168.345 8.30824 173.657 8.30824C178.968 8.30824 183.304 12.6263 183.304 17.9283C183.304 23.2303 178.968 27.5484 173.657 27.5484Z"
        fill="black"
      />
      <path
        d="M147.858 22.8477C152.194 21.2079 155.175 17.7644 155.175 12.2438V12.1345C155.175 8.90955 154.145 6.23124 152.248 4.31815C149.971 2.07711 146.611 0.874606 142.275 0.874606H59.0238V20.4974C59.0238 25.3621 56.5306 27.8764 52.3572 27.8764C48.1838 27.8764 45.6906 25.2527 45.6906 20.2787V0.819946H37.398V20.3334C37.398 30.3907 43.0348 35.5287 52.2488 35.5287C61.4628 35.5287 67.2622 30.4454 67.2622 20.1147V9.07353H75.7174V35.0368H83.9558V9.07353H95.8256L99.0776 1.31188V35.0368H107.316L107.533 24.1049H119.674L123.197 15.906H107.316V9.07353H126.665V35.0368H134.904V24.1049H139.294L146.611 35.0368H156.259L147.858 22.8477ZM146.99 12.7904C146.99 15.1407 145.364 16.7805 142.492 16.7805H134.85V8.74557H142.383C145.256 8.74557 146.99 10.1121 146.99 12.7357V12.7904Z"
        fill="black"
      />
      <path
        d="M17.9944 0C8.0758 0 0 8.03495 0 17.9283C0 27.8217 8.0758 35.8566 17.9944 35.8566C27.913 35.8566 35.9888 27.8217 35.9888 17.9283C35.9346 8.03495 27.8588 0 17.9944 0ZM17.9944 27.5484C12.6828 27.5484 8.3468 23.2303 8.3468 17.9283C8.3468 12.6263 12.6828 8.30824 17.9944 8.30824C23.306 8.30824 27.642 12.6263 27.642 17.9283C27.642 23.2303 23.306 27.5484 17.9944 27.5484Z"
        fill="black"
      />
      <path
        d="M259.889 0.983887H271L245.418 60.6721H234.307L259.889 0.983887Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M174.246 61H176.094V47.416L182.214 56.392H182.31L188.43 47.392V61H190.326V44.2H188.406L182.286 53.368L176.166 44.2H174.246V61Z"
        fill="black"
      />
      <path
        d="M193.162 61H195.106L197.098 56.56H206.242L208.21 61H210.25L202.594 44.08H200.818L193.162 61ZM197.842 54.832L201.682 46.288L205.498 54.832H197.842Z"
        fill="black"
      />
      <path
        d="M213.083 61H218.915C224.195 61 227.843 57.328 227.843 52.6V52.552C227.843 47.824 224.195 44.2 218.915 44.2H213.083V61ZM218.915 45.952C223.163 45.952 225.875 48.88 225.875 52.6V52.648C225.875 56.368 223.163 59.248 218.915 59.248H214.979V45.952H218.915Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="228.683"
          y1="55.0011"
          x2="277.028"
          y2="7.06293"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64008F" />
          <stop offset="1" stopColor="#AD4BAA" />
        </linearGradient>
      </defs>
    </>
  ),
};

export default OutfrontLogo;
