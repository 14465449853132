import {Geometry} from './Geometry';

export interface Location {
  bearing: number;
  center: Geometry;
  centerGeoPoint?: {lat: number; lon: number};
  viewshed: Geometry;
  primaryStreet?: string;
  crossStreet?: string;
  neighborhood?: string;
  locality?: string;
  county?: string;
}

export enum LocationType {
  'location.blockGroupId' = 'Block Group',
  'location.censusTractId' = 'Census Tract',
  'location.county.text' = 'County',
  'location.csa.text' = 'CSA',
  'location.cbsa.text' = 'CBSA',
  'location.neighborhood.text' = 'Neighborhood',
  'location.state.text' = 'State',
  'location.zip' = 'Zip Code',
  'location.place.text' = 'Place',
  'location.dma.text' = 'DMA',
}

export enum LocationTypeWithText {
  'Block Group' = 'location.blockGroupId',
  'Census Tract' = 'location.censusTractId',
  'County' = 'location.county.text',
  'CSA' = 'location.csa.text',
  'CBSA' = 'location.cbsa.text',
  'Neighborhood' = 'location.neighborhood.text',
  'State' = 'location.state.text',
  'Zip Code' = 'location.zip',
  'Place' = 'location.place.text',
  'DMA' = 'location.dma.text',
}

export enum LocationTypeWithoutText {
  'Block Group' = 'location.blockGroupId',
  'Census Tract' = 'location.censusTractId',
  'County' = 'location.county',
  'CSA' = 'location.csa',
  'CBSA' = 'location.cbsa',
  'Neighborhood' = 'location.neighborhood',
  'State' = 'location.state',
  'Zip Code' = 'location.zip',
  'Place' = 'location.place',
  'DMA' = 'location.dma',
}

export enum RawLocationType {
  'Block Group' = 'blockGroupId',
  'Census Tract' = 'censusTractId',
  'County' = 'county',
  'CSA' = 'csa',
  'CBSA' = 'cbsa',
  'Neighborhood' = 'neighborhood',
  'State' = 'state',
  'Zip Code' = 'zip',
  'Place' = 'place',
  'DMA' = 'dma',
}

export interface LocationFilter {
  type: LocationType;
  values: string[];
}

export interface LocationFilterQuery {
  type: string;
  value: string;
}

export const ungroupedLocationTypes = ['CSA', 'CBSA', 'State', 'DMA', 'Census Tract'];
