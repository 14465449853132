import {ReactNode} from 'react';
import {authentication} from '@onsmart/auth-client';
import {getOptionsFromApps, ServiceApp, ServiceOption} from '@onsmart/ui-kit';
import {createSelector} from 'reselect';

import {actions} from 'config/redux/global';
import {RootState} from 'config/redux/rootReducer';
import {Role} from 'models';

import {getUserRoles} from '../HasRole';

type User = RootState['authorization']['user'];

export interface MapStateToProps {
  activeNavLink: string;
  breadcrumbs: (string | ReactNode)[];
  loading: boolean;
  snackbarOpen: boolean;
  message: string;
  pageTitle: string;
  user: User;
  roles: Role[];
  serviceOptions: ServiceOption[];
  isCurator: boolean;
  isEditor: boolean;
  isPublisher: boolean;
}

export interface MapDispatchToProps {
  hideMessage: () => void;
  logout: () => void;
  navigateToPanelsPage: () => void;
  openSettingsPage: () => void;
}

const isUser = (type: Role) => createSelector([getUserRoles], (roles) => roles.includes(type));

const services: {[key in Role]: ServiceApp[]} = {
  [Role.admin]: [ServiceApp.dmp, ServiceApp.mad, ServiceApp.admin],
  [Role.dmpEditor]: [ServiceApp.dmp],
  [Role.dmpGuest]: [ServiceApp.dmp],
  [Role.madCurator]: [ServiceApp.mad],
  [Role.madEditor]: [ServiceApp.mad],
  [Role.madMarketOwner]: [ServiceApp.mad],
  [Role.madPublisher]: [ServiceApp.mad],
  [Role.padEditor]: [],
};

const getServiceOptions = createSelector([getUserRoles], (roles) => {
  const serviceSet = new Set<ServiceApp>();

  roles.forEach((role) => {
    const roleServices = services[role];
    roleServices.forEach((s) => serviceSet.add(s));
  });

  const serviceCollection = Array.from(serviceSet).filter((s) => s !== ServiceApp.mad);
  return getOptionsFromApps(serviceCollection);
});

export const mapStateToProps = (state) => {
  return {
    activeNavLink: window.location.hash,
    breadcrumbs: state.app.breadcrumbs,
    loading: state.app.loading,
    pageTitle: state.app.pageTitle,
    user: state.authorization.user,
    roles: getUserRoles(state),
    serviceOptions: getServiceOptions(state),
    isCurator: isUser(Role.madCurator)(state),
    isEditor: isUser(Role.madEditor)(state),
    isPublisher: isUser(Role.madPublisher)(state),
  } as MapStateToProps;
};

export const mapDispatchToProps = (dispatch) =>
  ({
    logout: () => dispatch(authentication.actions.logout()),
    navigateToPanelsPage: () => dispatch(actions.navigateToRoute('/panels')),
    openSettingsPage: () => dispatch(authentication.actions.openSettingsPage()),
  } as MapDispatchToProps);
